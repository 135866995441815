import gql from 'not-graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import {
  AddServiceProviderUserOutput,
  DeleteServiceProviderUserOutput,
  GetServiceProviderUsersInput,
  GetServiceProviderUsersOutput,
  MutationAddServiceProviderUserArgs,
  MutationDeleteServiceProviderUserArgs,
  MutationUpdateServiceProviderUserArgs,
  UpdateServiceProviderUserOutput,
} from '../../__generated__/pgeplus-types';
import useInstallerAuthQuery from './useInstallerAuthQuery';

export const addUserMutationQuery = gql`
  mutation AddServiceProviderUser($input: AddServiceProviderUserInput!) {
    addServiceProviderUser(input: $input) {
      user {
        birthDate
        firstName
        email
        id
        lastName
        phoneNumber
        role
      }
      errorReason {
        code
        message
        reference {
          category
          number
        }
      }
    }
  }
`;

export const updateUserMutationQuery = gql`
  mutation Mutation($input: UpdateServiceProviderUserInput!) {
    updateServiceProviderUser(input: $input) {
      errorReason {
        code
        message
        reference {
          category
          number
        }
      }
      id
    }
  }
`;

export const deleteUserMutationQuery = gql`
  mutation Mutation($input: DeleteServiceProviderUserInput!) {
    deleteServiceProviderUser(input: $input) {
      deleted
    }
  }
`;

export const getUserQuery = gql`
  query Query($input: GetServiceProviderUsersInput) {
    getServiceProviderUsers(input: $input) {
      users {
        birthDate
        email
        firstName
        id
        lastName
        role
        phoneNumber
      }
    }
  }
`;

export const useInstallerManageUser = () => {
  const [addUserMutation] = useMutation<
    {
      addServiceProviderUser: AddServiceProviderUserOutput;
    },
    MutationAddServiceProviderUserArgs
  >(addUserMutationQuery, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
  });

  const [updateUserMutation] = useMutation<
    {
      updateServiceProviderUser: UpdateServiceProviderUserOutput;
    },
    MutationUpdateServiceProviderUserArgs
  >(updateUserMutationQuery, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
    errorPolicy: 'all',
  });

  const [deleteUserMutation] = useMutation<
    {
      deleteServiceProviderUser: DeleteServiceProviderUserOutput;
    },
    MutationDeleteServiceProviderUserArgs
  >(deleteUserMutationQuery, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
  });

  const getUsersList = () => {
    const { loading, data, error, refetch } = useInstallerAuthQuery<{
      getServiceProviderUsers: GetServiceProviderUsersOutput;
      input: GetServiceProviderUsersInput;
    }>(getUserQuery, {
      variables: {
        input: {
          serviceProviderId: '',
        },
      },
      context: { pgePlus: true },
      fetchPolicy: 'no-cache',
    });

    return {
      loading,
      userData: data?.getServiceProviderUsers?.users,
      refetchUsersList: refetch,
    };
  };

  return {
    addUserMutation,
    updateUserMutation,
    deleteUserMutation,
    getUsersList,
  };
};
