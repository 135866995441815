import React, { FC, Fragment } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { DropdownMenu } from '../../pge-plus-common/DropdownMenu';
import { FormState } from '../../../hooks/useFormState.types';
import { TextAreaInput } from '../../pge-plus-common/text-area-input';
import { CancelFormState } from '.';

interface Props {
  formState: FormState<CancelFormState>;
  content: PageTextContentType;
}

const CancelForm: FC<Props> = ({ formState, content }: Props) => {
  const theme = useTheme();

  return (
    <Fragment>
      <Grid container item xs={12} css={{ rowGap: 20 }}>
        <Grid item xs={12}>
          <Typography
            variant={'body1'}
            css={{
              fontSize: '20px !important',
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
              },
            }}
          >
            {content.get('CANCEL_REASON_SELECTION')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          css={{
            '.MuiFormControl-root': {
              backgroundColor: 'unset !important',
            },
          }}
        >
          <DropdownMenu
            name={content.get('REASON')}
            options={[
              {
                value: `${content.get('PERSONAL_FAMILY_EMERGENCY')}`,
                label: `${content.get('PERSONAL_FAMILY_EMERGENCY')}`,
              },
              {
                value: `${content.get('ILLNESS')}`,
                label: `${content.get('ILLNESS')}`,
              },
              {
                value: `${content.get('CUSTOMER_REQUESTS_CANCELLATION')}`,
                label: `${content.get('CUSTOMER_REQUESTS_CANCELLATION')}`,
              },
              {
                value: `${content.get('RELATIONSHIP_OTHER')}`,
                label: `${content.get('RELATIONSHIP_OTHER')}`,
              },
            ]}
            state={formState.values.cancelReason || ''}
            setState={e => {
              void formState.setValue('cancelReason', e);
            }}
            errorMsg={(formState.errors.cancelReason as string) || ''}
          />
        </Grid>
        {formState.values.cancelReason ===
          content.get('RELATIONSHIP_OTHER') && (
          <Grid item xs={12}>
            <TextAreaInput
              {...formState.props('otherReason')}
              rowsMax={8}
              rowsMin={8}
              placeholder={content.get('RELATIONSHIP_OTHER')}
              value={formState.values.otherReason}
              errorMsg={formState.errors.otherReason as string}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default CancelForm;
