import { IconButton, MenuItem, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import SettingsIcon from '../../pge-plus-common/static/settings.svg';
import theme from '../../../themes/theme';
import TextLink from '../../text-link';
import routes from '../../../routes';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { useServiceProviderInfo } from '../../../hooks/pge-plus/useInstallerAccountInfo';
import { ServiceProviderUserRole } from '../../../__generated__/pgeplus-types';

type Props = {
  content: PageTextContentType;
};
const StyledMenuItem = ({
  children,
  handleClose,
}: {
  children: React.ReactNode;
  handleClose: () => void;
}) => {
  return (
    <MenuItem
      onClick={handleClose}
      css={{
        padding: theme.spacing(2, 2),
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      {children}
    </MenuItem>
  );
};

const AdminMenu = ({ content }: Props) => {
  const { loading, role } = useServiceProviderInfo();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading || !role || role === ServiceProviderUserRole.Editor) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        css={{ marginRight: theme.spacing(3) }}
      >
        <SettingsIcon width={32} height={32} />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        css={{
          '& .MuiPopover-paper': {
            minWidth: '210px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem handleClose={handleClose}>
          <TextLink plain to={routes.PGE_PLUS_INSTALLER_PORTAL_MANAGE_USER}>
            {content.get('MANAGE_USERS_TITLE')}
          </TextLink>
        </StyledMenuItem>
      </Popover>
    </>
  );
};

export default AdminMenu;
