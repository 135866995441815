import React, { FC, Fragment } from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { subText1, boldText, subTextWithoutMargin } from './quoteCss';
import { JobLocation } from '../../../__generated__/pgeplus-types';

interface Props {
  applicationNo: string;
  jobData: JobLocation;
  content: PageTextContentType;
}

const QuoteCustomerInfo: FC<Props> = ({
  applicationNo,
  jobData,
  content,
}: Props) => {
  const theme = useTheme();

  return (
    <Fragment>
      <Grid container item xs={12} css={{ rowGap: 20 }}>
        <Grid item xs={12}>
          <Typography css={{ ...subText1 }}>
            {content.get('CUSTOMER_INFORMATION')}
          </Typography>
          <Box css={{ marginTop: theme.spacing(2) }}>
            <Typography css={{ ...subTextWithoutMargin }}>
              {content.get('REUSABLE_JOB_NUMBER_COLON')}
            </Typography>
            <Typography css={{ ...subTextWithoutMargin, ...boldText }}>
              {applicationNo}
            </Typography>
          </Box>
          <Box css={{ marginTop: theme.spacing(2) }}>
            <Typography css={{ ...subTextWithoutMargin }}>
              {content.get('REUSABLE_NAME_OF_CUSTOMER_COLON')}
            </Typography>
            <Typography css={{ ...subTextWithoutMargin, ...boldText }}>
              {jobData?.firstName}
            </Typography>
          </Box>
          <Box css={{ marginTop: theme.spacing(2) }}>
            <Typography css={{ ...subTextWithoutMargin }}>
              {content.get('REUSABLE_ADDRESS_OF_INSTALLATION_COLON')}
            </Typography>
            <Typography css={{ ...subTextWithoutMargin, ...boldText }}>
              {jobData?.addressLine1}
            </Typography>
          </Box>
          <Box css={{ marginTop: theme.spacing(2) }}>
            <Typography css={{ ...subTextWithoutMargin }}>
              {content.get('REUSABLE_EMAIL_COLON')}
            </Typography>
            <Typography css={{ ...subTextWithoutMargin, ...boldText }}>
              {jobData?.email}
            </Typography>
          </Box>
          <Box css={{ marginTop: theme.spacing(2) }}>
            <Typography css={{ ...subTextWithoutMargin }}>
              {content.get('REUSABLE_PHONE_COLON')}
            </Typography>
            <Typography css={{ ...subTextWithoutMargin, ...boldText }}>
              {jobData?.phone}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default QuoteCustomerInfo;
