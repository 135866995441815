/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, Grid } from '@material-ui/core';
import PasswordTextField from '../../../password-text-field';
import PGEButton from '../../../buttons';
import useFormState from '../../../../hooks/useFormState';
import useWrapWithLoader from '../../../../hooks/useWrapWithLoading';
import PasswordIndicatorList from '../../../password-indicator-list';
import { isValidPassword } from '../../../../util/form-validation';
import { RouteComponentProps } from '@reach/router';
import theme from '../../../../themes/theme';
import validationRules from './ChangePassword.rules';
import { AuthErrorCodes, updatePassword } from 'firebase/auth';
import { auth } from '../../../../firebase';
import { FirebaseError } from 'firebase/app';
import useUtil from '../../../../hooks/pge-plus/useUtil';
import useContentMgmt from '../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../pge-plus-common/microcopyGroupIds';
import colors from '../../../../themes/main-colors';
import { Link } from 'gatsby';
import ROUTES from '../../../../routes';

const useStyles = makeStyles(() =>
  createStyles({
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

const ChangePassword: FunctionComponent<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const classes = useStyles();

  const { wrapWithLoader } = useWrapWithLoader();
  const { setErrorNotification } = useUtil();

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_CHANGE_PASSWORD,
  );

  const form = useFormState(
    {
      password: '',
    },
    {
      validate: validationRules,
    },
  );

  const onSubmit = wrapWithLoader(
    form.submit(async data => {
      try {
        await updatePassword(auth.currentUser!, data.password);
        setErrorNotification(
          true,
          content.get('CHANGE_PASSWORD_SUCCESS'),
          'success',
        );
        form.reset();
      } catch (e) {
        if (
          (e as FirebaseError)?.code ===
          AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN
        ) {
          setErrorNotification(
            true,
            content.get('CREDENTIAL_TOO_OLD_LOGIN_AGAIN'),
          );
        } else {
          setErrorNotification(
            true,
            content.get('GENERIC_ERROR_NOTIFICATION_MESSAGE'),
          );
        }
      }
    }),
  );

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid container spacing={2} css={{ marginTop: theme.spacing(2) }}>
        <Grid
          xs={12}
          item
          css={{
            marginTop: 16,
          }}
        >
          <span css={{ color: colors.sparkBlue }}>{'<'}</span>
          <Link
            to={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING}
            css={{
              fontSize: '18px',
              fontWeight: 700,
            }}
          >
            {content.get('BACK_TO_JOBS_LIST')}
          </Link>
        </Grid>
        <Grid item>
          <Typography variant={'h1'}>
            {content.get('CHANGE_PASSWORD_BUTTON')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={'h2'}>
                    {content.get('ENTER_NEW_PASSWORD')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PasswordTextField
                    name="password"
                    style={{ width: '100%' }}
                    inputProps={{ minLength: 8, maxLength: 16 }}
                    {...form.props('password')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PasswordIndicatorList password={form.values.password} />
                </Grid>
                <Grid item xs={12} className={classes.actionArea}>
                  <PGEButton
                    className={classes.submitButton}
                    type={'submit'}
                    variant="contained"
                    color="primary"
                    disabled={!isValidPassword(form.values.password)}
                  >
                    {content.get('CHANGE_PASSWORD_BUTTON')}
                  </PGEButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePassword;
