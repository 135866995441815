import useFormState from '../../../hooks/useFormState';
import jobQuoteFormValidationRules, { JobQuoteModal } from './QuoteForm.rules';
import {
  ApplicationEv,
  ApplicationEvSectionType,
  LineItemCostCategory,
  LineItemType,
  RebateSmartCharging,
  SectionEvChargerSelection,
  SectionEvRebates,
  SubmitJobDetailsOutput,
  UpsertQuoteOutput,
} from '../../../__generated__/pgeplus-types';
import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  createQuoteMutation,
  submitJobDetailsMutation,
} from '../installer-common/installerMutations';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { navigate } from 'gatsby';
import ROUTES from '../../../../src/routes';
import { LINE_ITEM_KEY, calculateItems } from '../quote.types';
import { parseTimeRange } from '../../../util/date-utils';
import useCalculateRebate from '../../../hooks/pge-plus/useCalculateRebate';
import useUtil from '../../../hooks/pge-plus/useUtil';

const defaultFormState: JobQuoteModal = {
  preferedDay: '',
  preferedTime: '',
  installationDate: '',
  installationTime: '',
  jobType: '',
  chargerModel: '',
  chargerCost: '',
  meterBase: '',
  weatherHead: '',
  electricBreaker: '',
  labor: '',
  miscMaterial: '',
  totalInstallationMaterialCost: 0,
  material: '',
  permitCost: '',
  travelCost: '',
  miscellaneousWorkCost: '',
  feesCost: '',
  additionalComment: '',
  additionalNotesToCustomer: '',
  modalIdNumber: '',
  panelLocation: '',
  distance: '',
  outsidePanelLocation: '',
  cardinalDirections: '',
  isDistance: '',
  isReplace: '',
  amperage: '',
  rebateEligibility: '',
  rebateType: '',
  panelUpgrade: '',
};
export default (content: any) => {
  const { wrapWithLoader } = useWrapWithLoader();
  const [rebateObject, setRebateObject] = useState({
    key: '',
    name: '',
    amount: 0,
    rebateItems: {
      panelUpgrade: 0,
      evCharger: 0,
    },
  });
  const [totalCost, setTotalCost] = useState(0);
  const [
    totalInstallationMaterialCost,
    setTotalInstallationMaterialCost,
  ] = useState(0);
  const [rebateAmount, setRebateAmount] = useState(0);
  const [totalInstallation, setTotalInstallation] = useState<number>();
  const [costBeforeRebate, setCostBeforeRebate] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [chargerModelList, setChargerModelList] = useState<
    { label: string; value: string }[]
  >([]);
  const { calculateEVRebateAmounts } = useCalculateRebate();
  const [isCalculatingRebates, setIsCalculatingRebates] = useState<boolean>(
    false,
  );
  const { setErrorNotification } = useUtil();

  const jobQuoteForm = useFormState(defaultFormState, {
    validate: jobQuoteFormValidationRules,
    validationContext: {
      content,
    },
  });

  const [createCompleteQuote] = useMutation<{
    upsertQuote: UpsertQuoteOutput;
  }>(createQuoteMutation, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
  });

  const [submitJobDetails] = useMutation<{
    submitJobDetails: SubmitJobDetailsOutput;
  }>(submitJobDetailsMutation, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
  });

  const calculateTotalAmount = (
    isOpen: boolean,
    calculateItem: calculateItems,
  ) => {
    setIsUpdate(!isUpdate);
    const {
      totalInstallationMaterial,
      chargerCost,
      meterBase,
      weatherHead,
      electricBreaker,
      labor,
      miscMaterial,
      material,
      permitCost,
      travelCost,
      feesCost,
      miscellaneousWorkCost,
    } = calculateItem;

    if (isOpen) {
      setTotalInstallationMaterialCost(
        Number(meterBase || 0) +
          Number(weatherHead || 0) +
          Number(electricBreaker || 0) +
          Number(labor || 0) +
          Number(miscMaterial || 0) +
          Number(material || 0),
      );
      void jobQuoteForm.setValue(
        'totalInstallationMaterialCost',
        Number(meterBase || 0) +
          Number(weatherHead || 0) +
          Number(electricBreaker || 0) +
          Number(labor || 0) +
          Number(miscMaterial || 0) +
          Number(material || 0),
      );
      setTotalInstallation(
        Number(meterBase || 0) +
          Number(weatherHead || 0) +
          Number(electricBreaker || 0) +
          Number(labor || 0) +
          Number(miscMaterial || 0) +
          Number(material || 0) +
          Number(permitCost || 0) +
          Number(travelCost || 0) +
          Number(feesCost || 0) +
          Number(miscellaneousWorkCost || 0),
      );
      setTotalCost(
        Number(chargerCost || 0) +
          Number(meterBase || 0) +
          Number(weatherHead || 0) +
          Number(electricBreaker || 0) +
          Number(labor || 0) +
          Number(miscMaterial || 0) +
          Number(material) +
          Number(permitCost || 0) +
          Number(travelCost || 0) +
          Number(feesCost || 0) +
          Number(miscellaneousWorkCost || 0),
      );
      setCostBeforeRebate(
        Number(meterBase || 0) +
          Number(weatherHead || 0) +
          Number(electricBreaker || 0) +
          Number(labor || 0) +
          Number(miscMaterial || 0) +
          Number(material || 0) +
          Number(permitCost || 0) +
          Number(travelCost || 0) +
          Number(feesCost || 0) +
          Number(miscellaneousWorkCost || 0) +
          Number(chargerCost || 0),
      );
    } else {
      setTotalInstallation(
        Number(totalInstallationMaterial) +
          Number(permitCost || 0) +
          Number(travelCost || 0) +
          Number(feesCost || 0) +
          Number(miscellaneousWorkCost || 0),
      );
      setTotalCost(
        (Number(chargerCost) || 0) +
          Number(totalInstallationMaterial || 0) +
          Number(permitCost || 0) +
          Number(travelCost || 0) +
          Number(feesCost || 0) +
          Number(miscellaneousWorkCost || 0),
      );
      setCostBeforeRebate(
        Number(totalInstallationMaterial || 0) +
          Number(permitCost || 0) +
          Number(travelCost || 0) +
          Number(feesCost) +
          Number(miscellaneousWorkCost || 0) +
          Number(chargerCost || 0),
      );
    }
  };

  const resetExpandFormData = async () => {
    void resetFormKey('meterBase');
    void resetFormKey('weatherHead');
    void resetFormKey('electricBreaker');
    void resetFormKey('labor');
    void resetFormKey('miscMaterial');
    void resetFormKey('material');
  };

  const resetFormKey = (key: keyof JobQuoteModal) =>
    jobQuoteForm.setValue(key, '');

  const submitCreateQuote = wrapWithLoader(
    async (jobId: string, route?: string) => {
      const formatedDate = parseTimeRange(
        jobQuoteForm?.values?.installationTime!,
        jobQuoteForm?.values?.installationDate!,
      );

      const startDateTime = formatedDate?.start;
      const endDateTime = formatedDate?.end;

      const quoteCreateInput = {
        quote: {
          jobId: jobId,
          total: Number(totalCost - rebateAmount),
          serviceTime: {
            start: startDateTime,
            end: endDateTime,
          },
          data: {
            lineItems: submitLineItems(true),
          },
        },
      };

      const { data: createQuoteData } = await createCompleteQuote({
        variables: {
          input: quoteCreateInput,
        },
      });
      if (createQuoteData?.upsertQuote?.quote) {
        if (route) {
          void (await navigate(route));
        } else {
          void (await navigate(
            ROUTES.PGE_PLUS_INSTALLER_PORTAL_QUOTE_SUBMITTED.replace(
              ':jobId',
              createQuoteData?.upsertQuote?.quote?.jobId,
            ),
          ));
        }
      }
    },
  );

  const submitJob = wrapWithLoader(async (jobId: string) => {
    const jobDetailsInput = {
      jobDetails: {
        jobId: jobId,
        total: Number(totalCost - rebateAmount),
        jobDate: new Date(),
        data: {
          images: [
            {
              key: 'IMAGE_CHARGER_SERIAL_NO',
              name: 'Charger serial no',
              objectId: jobQuoteForm.values.chargerPhoto!,
            },
            {
              key: 'IMAGE_CHARGER_INSTALLATION',
              name: 'Charger installation',
              objectId: jobQuoteForm.values.installedChargerPhoto!,
            },
            {
              key: 'IMAGE_CUSTOMER_INVOICE',
              name: 'Customer invoice',
              objectId: jobQuoteForm.values.customerInvoicePhoto!,
            },
          ],
          lineItems: submitLineItems(false),
        },
      },
    };

    const { data: submitJobData } = await submitJobDetails({
      variables: {
        input: jobDetailsInput,
      },
    });
    if (submitJobData?.submitJobDetails?.jobDetails?.id) {
      void (await navigate(
        ROUTES.PGE_PLUS_INSTALLER_PORTAL_SUCCESS.replace(
          ':jobId',
          submitJobData?.submitJobDetails?.jobDetails?.id,
        ),
      ));
    }
  });

  const rebateCalculate = async (applicationData: ApplicationEv) => {
    if (!applicationData) {
      return;
    }

    const sectionEvRebates = applicationData?.details?.find(
      sec => sec.sectionType === ApplicationEvSectionType.EvRebates,
    ) as SectionEvRebates;

    if (!sectionEvRebates.data?.rebateEligibility?.eligible) {
      return;
    }

    const object = {
      key: '',
      name: '',
      amount: 0,
      rebateItems: {
        //TODO: 'panelUpgrade' is misleading, should be renamed to installationRebate.
        //This should also be fixed in final quote for customer page - src\components\pge-plus-form\FinalQuote\index.tsx
        panelUpgrade: 0,
        evCharger: 0,
      },
    };

    const productInfo = applicationData?.details?.find(
      sec => sec.sectionType === ApplicationEvSectionType.EvChargerSelection,
    ) as SectionEvChargerSelection;

    const eligibilityCheck = applicationData?.details?.find(
      sec => sec.sectionType === ApplicationEvSectionType.EvRebates,
    ) as SectionEvRebates;
    const typeOfRebate = eligibilityCheck?.data?.rebateEligibility
      ?.rebate as RebateSmartCharging;
    const chargerCost = Number(jobQuoteForm?.values?.chargerCost);
    let rebateName = '';
    let rebateCode = '';

    const hasPanelUpgrade = jobQuoteForm?.values?.isReplace === 'yes';
    if (typeOfRebate) {
      if (typeOfRebate?.__typename === 'RebateSmartCharging') {
        rebateCode = typeOfRebate?.iqdb ? 'H' : 'S';
        rebateCode =
          hasPanelUpgrade && typeOfRebate?.panelUpgrade
            ? `${rebateCode}P`
            : rebateCode;
      } else if (typeOfRebate?.__typename === 'RebateEVPulse') {
        rebateCode = 'EVP';
      } else {
        rebateCode = '';
      }
    }
    rebateName = rebateCodeMap(rebateCode);
    object.key = rebateCode;
    object.name = rebateName;

    if (applicationData && totalInstallation) {
      try {
        setIsCalculatingRebates(true);
        console.log(applicationData);

        const { data } = await calculateEVRebateAmounts({
          applicationId: applicationData?.id,
          isInstalledByPgePlus: true,
          isPanelReplaced: jobQuoteForm?.values?.isReplace === 'yes',
          isProductSuppliedByCustomer: productInfo?.data?.suppliedByCustomer,
          installationCost: totalInstallation,
          productCost: productInfo?.data?.suppliedByCustomer
            ? null
            : chargerCost,
        });

        object.amount =
          data.calculateEVRebateAmounts.installerGivenRebates.chargerRebate +
          data.calculateEVRebateAmounts.installerGivenRebates
            .installationRebate;
        object.rebateItems.panelUpgrade =
          data.calculateEVRebateAmounts.installerGivenRebates.installationRebate;
        object.rebateItems.evCharger =
          data.calculateEVRebateAmounts.installerGivenRebates.chargerRebate;

        setRebateAmount(object.amount || 0);
        void jobQuoteForm.setValue('rebateType', typeOfRebate?.__typename!);
        setRebateObject(object);
      } catch (e) {
        setErrorNotification(true);
      } finally {
        setIsCalculatingRebates(false);
      }
    }
  };

  const rebateCodeMap = (record: string) => {
    if (record === 'EVP') {
      return 'Tesla non-connected Level 2 charger rebate';
    } else if (record === 'H') {
      return 'Income eligible charger rebate';
    } else if (record === 'HP') {
      return 'Income eligible panel upgrade rebate';
    } else if (record === 'S') {
      return 'Standard Charger Rebate';
    } else if (record === 'SP') {
      return 'Standard panel upgrade rebate';
    } else {
      return '';
    }
  };

  const submitLineItems = (isCreate: boolean) => {
    return [
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.JOB_TYPE,
        name: content.get('JOB_TYPE'),
        value: jobQuoteForm?.values?.jobType || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.PANEL_LOCATION_MAIN_BREAKER,
        name: content.get('REUSABLE_MAIN_BREAKER_PANEL_LOCATION'),
        value: jobQuoteForm?.values?.panelLocation || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.PREFERRED_INSTALL_DISTANCE,
        name: content.get('DISTANCE_FROM_CHARGER_TO_PANEL'),
        value: jobQuoteForm?.values?.distance || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.PANEL_LOCATION,
        name: content.get('WHERE_IS_PANEL_LEFT_BACK_RIGHT'),
        value: jobQuoteForm?.values?.outsidePanelLocation || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.PANEL_LOCATION_CARDINAL_DIRECTION,
        name: content.get('PANEL_CARDINAL_DIRECTION'),
        value: jobQuoteForm?.values?.cardinalDirections || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.CHARGER_INSTALL_WITHIN_PANEL_DISTANCE,
        name: content.get('WILL_PANEL_BE_INSTALLED_WITHIN_DISTANCE'),
        value: jobQuoteForm?.values?.isDistance || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.PANEL_UPGRADE,
        name: content.get('REPLACE_PANEL'),
        value: jobQuoteForm?.values?.isReplace || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.PANEL_AMPERAGE,
        name: content.get('CONFIRM_AMPERAGE_MAIN_BREAKER'),
        value: jobQuoteForm?.values?.amperage || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.COMMENTS,
        name: content.get('ADDITIONAL_COMMENTS'),
        value: jobQuoteForm?.values?.additionalComment || '',
      },
      {
        type: LineItemType.Detail,
        key: LINE_ITEM_KEY.CUSTOMER_VISIBLE_NOTES,
        name: '',
        value: jobQuoteForm?.values?.additionalNotesToCustomer || '',
      },
      isCreate
        ? {
            type: LineItemType.Product,
            key: LINE_ITEM_KEY.EV_CHARGER_MODEL,
            name:
              chargerModelList &&
              chargerModelList.find(
                item => item.value === jobQuoteForm?.values?.chargerModel,
              )?.label,
            value: jobQuoteForm?.values?.chargerModel || '',
            amount: Number(jobQuoteForm?.values?.chargerCost) || 0,
          }
        : {
            type: LineItemType.Product,
            key: LINE_ITEM_KEY.EV_CHARGER_MODEL,
            name:
              chargerModelList &&
              chargerModelList.find(
                item => item.value === jobQuoteForm?.values?.chargerModel,
              )?.label,
            value: jobQuoteForm?.values?.chargerModel || '',
            amount: Number(jobQuoteForm?.values?.chargerCost) || 0,
            productMeta: {
              serialNumber: jobQuoteForm?.values?.modalIdNumber,
            },
          },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.INSTALLATION_MATERIALS,
        name: content.get('INSTALLATION_AND_MATERIALS'),
        category: LineItemCostCategory.Services,
        amount:
          Number(jobQuoteForm?.values?.totalInstallationMaterialCost) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.METER_BASE,
        name: content.get('REUSABLE_METER_BASE'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.meterBase) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.WEATHER_HEAD,
        name: content.get('REUSABLE_WEATHERHEAD'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.weatherHead) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.ELECTRICAL_BREAKER,
        name: content.get('REUSABLE_NEW_PANEL'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.electricBreaker) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.LABOR,
        name: content.get('REUSABLE_LABOR'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.labor) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.MISC_MATERIALS,
        name: content.get('REUSABLE_MISC_MATERIALS'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.miscMaterial) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.MATERIALS,
        name: content.get('REUSABLE_MATERIALS'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.material) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.PERMITS,
        name: content.get('REUSABLE_PERMITS'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.permitCost) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.TRAVEL_TIME,
        name: content.get('REUSABLE_TRAVEL_TIME'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.travelCost) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.FEES,
        name: content.get('REUSABLE_FEES'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.feesCost) || 0,
      },
      {
        type: LineItemType.Cost,
        key: LINE_ITEM_KEY.MISCELLANEOUS_WORK,
        name: content.get('REUSABLE_MISC_WORK'),
        category: LineItemCostCategory.Services,
        amount: Number(jobQuoteForm?.values?.miscellaneousWorkCost) || 0,
      },
      ...(rebateObject.key && rebateObject.name
        ? [
            {
              type: LineItemType.Rebate,
              key: rebateObject.key,
              name: rebateObject.name,
              amount: rebateObject.amount,
              ...(jobQuoteForm?.values?.rebateType === 'RebateSmartCharging'
                ? {
                    rebateItems: rebateObject.rebateItems,
                  }
                : {}),
            },
          ]
        : []),
    ];
  };

  return {
    isCalculatingRebates,
    jobQuoteForm,
    defaultFormState,
    calculateTotalAmount,
    resetExpandFormData,
    submitCreateQuote,
    submitJob,
    rebateCalculate,
    setTotalCost,
    setCostBeforeRebate,
    setTotalInstallation,
    setTotalInstallationMaterialCost,
    rebateAmount,
    totalCost,
    isUpdate,
    totalInstallationMaterialCost,
    totalInstallation: totalInstallation || 0,
    costBeforeRebate,
    chargerModelList,
    setChargerModelList,
  };
};
