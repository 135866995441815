import React, { FunctionComponent as FC, useState, useEffect } from 'react';
import { Typography, Grid, Box, useTheme } from '@material-ui/core';
import { Link, navigate, useParams } from '@reach/router';
import { Layout } from '../../pge-plus-common/Layout';
import colors from '../../../themes/main-colors';
import useContentMgmt from '../../../hooks/useContentMgmt';
import ROUTES from '../../../routes';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { useInstallerJobDetailsQuery } from '../../../hooks/pge-plus/useInstallerJobDetailsQuery';
import {
  CancelJobOutput,
  MutationCancelJobArgs,
} from '../../../__generated__/pgeplus-types';
import useFormState, {
  convertValidationRules,
} from '../../../hooks/useFormState';
import { validateRequiredAndValidCharacters } from '../../../util/form-validation';
import CancelApplicationConfirmationModal from '../../pge-plus-common/CancelApplicationConfirmationModal';
import CancelForm from './CancelForm';
import Backdrop from '../../backdrop/Backdrop';
import { useMutation } from '@apollo/react-hooks';
import { cancelJobMutation } from '../../../queries/pge-plus/installerPortal.query';
import useUtil from '../../../hooks/pge-plus/useUtil';
import InstallerButton from '../../pge-plus-common/InstallerButton';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import InstallerPageHeader from '../../pge-plus-common/InstallerPageHeader';

interface Props {
  path?: string;
}

export type CancelFormState = {
  cancelReason?: string | undefined;
  otherReason?: string | undefined;
};

const JobCancel: FC<Props> = props => {
  const { jobId } = useParams();
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [cancelAppModalOpen, setCancelAppModalOpen] = useState(false);
  const { wrapWithLoader } = useWrapWithLoader();

  const [cancelJob] = useMutation<
    {
      cancelJob: CancelJobOutput;
    },
    MutationCancelJobArgs
  >(cancelJobMutation, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
  });

  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_RESCHEDULE_CANCEL,
  );

  const { loading, data, error } = useInstallerJobDetailsQuery(jobId);
  const { setErrorNotification } = useUtil();

  const validateForm = () =>
    convertValidationRules<CancelFormState, { content: Map<string, string> }>(
      context => {
        const { values, content: t } = context;
        return {
          cancelReason: validateRequiredAndValidCharacters.bind(null, 'reason'),
          otherReason:
            values.cancelReason === 'Other'
              ? validateRequiredAndValidCharacters.bind(null, 'reason')
              : null,
        };
      },
    );

  const formState = useFormState(
    {
      cancelReason: '',
      otherReason: '',
    },
    {
      validate: validateForm(),
      validationContext: {
        content,
      },
    },
  );

  const theme = useTheme();

  const cancelJobApplication = wrapWithLoader(async () => {
    try {
      const { data: cancelJobResponse } = await cancelJob({
        variables: {
          input: {
            jobId: data?.getJob?.job?.id!,
            reason: formState.values.cancelReason!,
            statusNotes:
              formState.values.cancelReason ===
              content.get('RELATIONSHIP_OTHER')
                ? formState.values.otherReason
                : undefined,
          },
        },
      });

      if (cancelJobResponse?.cancelJob?.canceled) {
        setCancelAppModalOpen(false);
        setCancelSuccess(true);
      } else {
        setCancelAppModalOpen(false);
        setErrorNotification(true);
      }
    } catch (e) {
      setCancelAppModalOpen(false);
      setErrorNotification(true);
    }
  });

  return (
    <Layout>
      {loading && <Backdrop forceOpen />}
      {data && (
        <>
          <Grid
            container
            css={{
              marginTop: 16,
              marginBottom: 96,
              justifyContent: 'center',
            }}
          >
            <Grid item container xs={12} md={7} css={{ rowGap: 30 }}>
              {!cancelSuccess && (
                <>
                  <Grid item xs={12}>
                    <InstallerPageHeader
                      pageHeaderInfo={{
                        pageTitle: content.get('CANCEL_INSTALLATION_HEADER'),
                      }}
                      jobData={data?.getJob}
                      content={content}
                    />
                  </Grid>
                  <CancelForm formState={formState} content={content} />

                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                        columnGap: 30,
                        [theme.breakpoints.down('sm')]: {
                          alignItems: 'center',
                          height: '96px',
                          backgroundColor: colors.cloudGrey,
                          position: 'fixed',
                          bottom: 0,
                          width: '100%',
                          left: 0,
                          boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
                        },
                      }}
                    >
                      <InstallerButton
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => {
                          formState.reset({
                            cancelReason: '',
                            otherReason: '',
                          });
                          void navigate(
                            ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_RESCHEDULE_OR_CANCEL.replace(
                              ':jobId',
                              jobId,
                            ),
                          );
                        }}
                      >
                        {content.get('REUSABLE_BUTTON_BACK')}
                      </InstallerButton>
                      <InstallerButton
                        type={'submit'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={formState.submit(() =>
                          setCancelAppModalOpen(true),
                        )}
                      >
                        {content.get('REUSABLE_CONFIRM')}
                      </InstallerButton>
                    </Box>
                  </Grid>
                </>
              )}

              {cancelSuccess && (
                <>
                  <Grid
                    xs={12}
                    item
                    css={{
                      marginTop: 16,
                    }}
                  >
                    <span css={{ color: colors.sparkBlue }}>{'<'}</span>
                    <Link
                      to={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING}
                      css={{
                        fontSize: '18px',
                        fontWeight: 700,
                      }}
                    >
                      {content.get('BACK_TO_JOBS_LIST')}
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'h1'}>
                      {content.get('YOU_HAVE_CANCELLED_JOB_HEADER')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant={'body1'}
                      css={{
                        fontSize: '20px !important',
                        [theme.breakpoints.down('sm')]: {
                          fontSize: '16px !important',
                        },
                        margin: '-30px 0',
                      }}
                    >
                      {richText(content.get('YOU_HAVE_CANCELLED_JOB_DETAILS'), {
                        jobId: data?.getJob?.application?.applicationNo,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                        [theme.breakpoints.down('sm')]: {
                          alignItems: 'center',
                          height: '96px',
                          backgroundColor: colors.cloudGrey,
                          position: 'fixed',
                          bottom: 0,
                          width: '100%',
                          left: 0,
                          boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
                        },
                      }}
                    >
                      <InstallerButton
                        color={'primary'}
                        variant={'contained'}
                        onClick={() =>
                          void navigate(
                            ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING,
                          )
                        }
                      >
                        {content.get('REUSABLE_BACK_TO_HOME')}
                      </InstallerButton>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <CancelApplicationConfirmationModal
            open={cancelAppModalOpen}
            microcopyGroupId={
              microcopyGroupIds.PGE_PLUS_INSTALLER_RESCHEDULE_CANCEL
            }
            data={{ applicationNo: data.getJob.application.applicationNo }}
            onClose={() => setCancelAppModalOpen(false)}
            onCancelAppConfirmation={cancelJobApplication}
          />
        </>
      )}
    </Layout>
  );
};

export default JobCancel;
