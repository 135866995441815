import React, { FC, Fragment } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { boldText, subTextWithoutMargin } from './quoteCss';
import { FormState } from '../../../hooks/useFormState.types';
import { JobQuoteModal } from '../confirm-cost/QuoteForm.rules';
import { PageTextContentType } from '../../../hooks/useContentMgmt';

interface Props {
  content: PageTextContentType;
  totalCost: number;
  costBeforeRebate: number;
  rebateAmount: number;
  isCalculatingRebates: boolean;
}

const QuoteTotalSection: FC<Props> = ({
  content,
  totalCost,
  costBeforeRebate,
  rebateAmount,
  isCalculatingRebates,
}: Props) => {
  const theme = useTheme();
  const totalInstallation = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  };
  const hr = {
    border: '1px solid #D7D7D7',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  };
  return (
    <Fragment>
      <Grid>
        <Box css={{ ...totalInstallation }}>
          <Typography css={{ ...boldText, fontWeight: 400 }}>
            {content.get('REUSABLE_COST_BEFORE_REBATES')}
          </Typography>
          <Typography css={{ ...boldText, fontWeight: 400 }}>
            ${costBeforeRebate.toFixed(2)}
          </Typography>
        </Box>
        <Box css={{ ...totalInstallation }}>
          <Typography css={{ ...boldText, fontWeight: 400 }}>
            {content.get('REUSABLE_REBATE_AMOUNT')}
          </Typography>
          <Typography css={{ ...boldText, fontWeight: 400 }}>
            {!isCalculatingRebates && (
              <Fragment>${rebateAmount.toFixed(2)}</Fragment>
            )}
            {isCalculatingRebates && <CircularProgress size={24} />}
          </Typography>
        </Box>
      </Grid>
      <hr css={{ ...hr }} />
      <Grid>
        <Box css={{ ...totalInstallation }}>
          <Typography css={{ ...boldText }}>
            {content.get('REUSABLE_TOTAL_COST')}
          </Typography>
          <Typography css={{ ...boldText }}>
            {!isCalculatingRebates && (
              <Fragment>
                ${(totalCost - rebateAmount).toFixed(2) || '0.00'}
              </Fragment>
            )}
            {isCalculatingRebates && <CircularProgress size={24} />}
          </Typography>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default QuoteTotalSection;
