import { Router } from '@reach/router';
import React from 'react';
import ChangePassword from './ChangePassword';
import routes from '../../../routes';
import AuthLayout from '../../auth-layout';

const Profile = () => {
  return (
    <AuthLayout footer={<></>} showLeft={false}>
      <Router basepath="/">
        <ChangePassword
          path={`${routes.PGE_PLUS_INSTALLER_PORTAL_CHANGE_PASSWORD}`}
        />
      </Router>
    </AuthLayout>
  );
};

export default Profile;
