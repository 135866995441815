import React, { useState, useEffect, FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Theme,
  Slide,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import useContentMgmt from '../../../hooks/useContentMgmt';
import { TransitionProps } from '@material-ui/core/transitions';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { useIsMobile } from '../../../util/style-utils';
import Avatar from '@material-ui/core/Avatar';
import useUtil from '../../../hooks/pge-plus/useUtil';
import { useInstallerManageUser } from '../../../hooks/pge-plus/useInstallerManageUser';
import Backdrop from '../../backdrop/Backdrop';
import { ServiceProviderUser } from '../../../__generated__/pgeplus-types';
import PGEButton from '../../buttons';

const PREFIX = 'deleteUser';
const classes = {
  dialogPaper: `${PREFIX}-dialog-paper`,
  dialogContent: `${PREFIX}-dialog-content`,
  dialogTitle: `${PREFIX}-dialog-title`,
  dialogActions: `${PREFIX}-dialog-actions`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.dialogPaper}`]: {
    maxWidth: '530px',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px !important',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
    },
  },
  [`& .${classes.dialogContent}`]: {
    padding: '0 30px',
  },
  [`& .${classes.dialogTitle}`]: {
    padding: '30px 30px 0',
  },
  [`& .${classes.dialogActions}`]: {
    padding: '30px 30px 50px',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  isVisible?: any;
  onSuccess: () => void;
  onClose: () => void;
  userId?: any;
  selectedUser?: ServiceProviderUser;
}

export const DeleteUserModel: FC<Props> = ({
  isVisible,
  onClose,
  onSuccess,
  userId,
  selectedUser,
}: Props) => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_DELETE_USER_SCREEN,
  );
  const { setErrorNotification } = useUtil();
  const isMobile = useIsMobile();

  const { deleteUserMutation } = useInstallerManageUser();

  const [dataUser, setDataUser] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = async () => {
    onClose();
  };

  useEffect(() => {
    setDataUser(selectedUser);
  }, [isVisible]);

  const onDeleteUser = async () => {
    setLoading(true);
    const { data, errors } = await deleteUserMutation({
      variables: {
        input: {
          id: userId,
        },
      },
    });
    if (errors) {
      setErrorNotification(true);
      setLoading(false);
    }
    if (data?.deleteServiceProviderUser?.deleted) {
      onClose();
      setLoading(false);
      setErrorNotification(
        true,
        content.get('USER_DELETED_SUCESSFULLY'),
        'success',
      );
      onSuccess();
    }
  };

  return (
    <StyledDialog
      open={isVisible}
      TransitionComponent={isMobile ? Transition : undefined}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      fullWidth={true}
      disableRestoreFocus={true}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      {loading && <Backdrop forceOpen />}
      <DialogTitle className={classes.dialogTitle}>
        <Grid
          className={'outageTitle'}
          container
          css={{
            justifyContent: 'end',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            css={{
              padding: 0,
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item>
            <Typography
              variant={'h2'}
              css={{
                fontSize: '36px',
                marginBottom: '20px',
                lineHeight: '43px',
              }}
            >
              {content.get('ARE_YOU_SURE_YOU_WANT_TO_DELETE_TITLE')}
            </Typography>
          </Grid>
          <Grid
            item
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar>{`${dataUser?.firstName?.charAt(
                  0,
                )}${dataUser?.lastName?.charAt(0)}`}</Avatar>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {`${dataUser?.firstName} ${dataUser?.lastName}`}
              </div>
            </div>
          </Grid>
          <Grid item>
            <Typography
              variant={'body1'}
              style={{ fontSize: '20px', marginTop: '20px' }}
            >
              {content.get('DELETING_USER_DETAILS')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container spacing={2}>
          <Grid xs={2} />
          <Grid xs={8}>
            <Grid
              container
              spacing={2}
              css={{
                marginTop: 16,
                marginBottom: 16,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Grid item xs={6}>
                <PGEButton
                  onClick={onClose}
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  css={{
                    fontSize: '18px',
                    borderRadius: '30px',
                    '.MuiButton-label': {
                      fontWeight: 500,
                    },
                    '&:hover': {
                      borderWidth: '2px',
                    },
                  }}
                >
                  {content.get('NO_CANCEL_BUTTON')}
                </PGEButton>
              </Grid>
              <Grid item xs={6}>
                <PGEButton
                  onClick={() => onDeleteUser()}
                  fullWidth
                  variant="contained"
                  css={{
                    fontSize: '18px',
                    borderRadius: '30px',
                    '.MuiButton-label': {
                      fontWeight: 500,
                    },
                  }}
                >
                  {content.get('YES_DELETE_BUTTON')}
                </PGEButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={2} />
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};
