import React, { Dispatch, Fragment, SetStateAction } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Grid, useTheme } from '@material-ui/core';
import { Search } from '../../controls/Search';
import { navigate } from 'gatsby';
import ROUTES from '../../../../../routes';
import Avatar from '@material-ui/core/Avatar';
import Menu, { MenuItem } from '../../../../menu/Menu';
import compact from 'lodash/compact';
import Typography from '@material-ui/core/Typography';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Pagination from '../../../../pagination';
import { PaginationProps } from '../../../../pagination/Pagination';
import { PageSize } from '../../../../pagination/types';
import PGEButton from '../../../../buttons';

type DesktopViewProps = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setIsVisibleAdd: Dispatch<SetStateAction<boolean>>;
  setSelectedData: Dispatch<SetStateAction<boolean>>;
  isVisible: any;
  isVisibleAdd: any;
  selectedData: any;
  setUserId: any;
  data: any;
  maxAllowedUsers: any;
  records: any;
  onPageChange: any;
  input: any;
};

export const Installer2MobileView = (props: DesktopViewProps) => {
  const {
    searchValue,
    setSearchValue,
    setIsVisible,
    setIsVisibleAdd,
    isVisible,
    isVisibleAdd,
    setUserId,
    data,
    maxAllowedUsers,
    records,
    onPageChange,
    input,
    setSelectedData,
  } = props;

  const theme = useTheme();
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_MANAGE_USER_SCREEN,
  );

  const onEdit = (value: any, event: any) => {
    setSelectedData(value);
    setIsVisibleAdd(!isVisibleAdd);
  };

  const onDelete = (value: any, event: any) => {
    setIsVisible(!isVisible);
    setUserId(value?.id);
    setSelectedData(value);
  };

  const AdminUserMenu: Array<MenuItem<any>> = compact([
    { text: 'Edit', onClick: onEdit },
  ]);

  const EditorUserMenu: Array<MenuItem<any>> = compact([
    { text: 'Edit', onClick: onEdit },
    { text: 'Delete user', onClick: onDelete },
  ]);

  const paginationProps: PaginationProps = {
    count: data?.length,
    pageSize: input?.pagination?.perPage,
    pageSizes: [],
    page: input?.pagination?.page,
    visibleOnSinglePage: true,
    onPageChange: (newPage: number, newPageSize: PageSize) => {
      onPageChange(newPage, newPageSize);
    },
  };

  return (
    <Fragment>
      <Grid
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(3),
        }}
      >
        <Typography
          css={{
            color: '#3D5265',
            fontSize: '20px',
            fontWeight: 'normal',
          }}
        >
          {`${content.get('MANAGE_USERS_TITLE')} (${data?.length})`}
        </Typography>
        <Tooltip
          title={
            data?.length >= maxAllowedUsers ? (
              <h3 style={{ fontSize: '14px' }}>
                {content.get('MAX_AMOUNT_USERS_PERMITTED') || ''}
              </h3>
            ) : (
              ''
            )
          }
          placement="top"
        >
          <div>
            <PGEButton
              css={{
                '& .MuiButton-label': {
                  gap: '5px',
                },
              }}
              style={{
                cursor: 'pointer',
                fontSize: '18px',
                color: '#27788C',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                background: 'none',
              }}
              onClick={() => {
                setIsVisibleAdd(!isVisibleAdd);
              }}
              disabled={data?.length >= maxAllowedUsers}
            >
              <AddCircleOutlineIcon />
              {content.get('ADD_USER')}
            </PGEButton>
          </div>
        </Tooltip>
      </Grid>
      <Grid
        css={{
          width: '100%',
        }}
      >
        <Search
          content={content}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Grid>
      <hr style={{ margin: '20px 0' }} />
      {records?.map((item: any) => (
        <Fragment>
          <Grid
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: theme.spacing(1),
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar>{`${item?.firstName?.charAt(0)}${item?.lastName?.charAt(
                  0,
                )}`}</Avatar>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ color: '#20415B' }}>
                  {`${item?.firstName} ${item?.lastName}`}
                </div>
                <div style={{ color: '#707070' }}>{item?.email}</div>
              </div>
            </div>
            <div>
              <Menu
                items={item?.role === 'ADMIN' ? AdminUserMenu : EditorUserMenu}
                value={item?.id}
              />
            </div>
          </Grid>
          <Grid
            css={{
              color: '#20415B',
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              marginBottom: '5px',
            }}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
              {content.get('DATE_OF_BIRTH')}:
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              {item?.birthDate}
            </Typography>
          </Grid>
          <Grid
            css={{
              color: '#20415B',
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              marginBottom: '5px',
            }}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
              {content.get('PHONE')}:
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              {item?.phoneNumber}
            </Typography>
          </Grid>
          <Grid
            css={{
              color: '#20415B',
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
            }}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
              {content.get('PERMISSIONS')}:
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              {item?.role}
            </Typography>
          </Grid>
          <hr style={{ margin: '20px 0' }} />
        </Fragment>
      ))}
      <Grid>
        <Pagination {...paginationProps} />
      </Grid>
    </Fragment>
  );
};
