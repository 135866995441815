import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core';
import colors from '../../themes/main-colors';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

export type JobSummarySectionProps = {
  sectionTitle?: string;
  linkText?: string;
  onLinkClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  items: {
    label?: string;
    values: string[];
  }[];
  isDownloading?: boolean;
};

export const JobSummarySection: React.FC<JobSummarySectionProps> = ({
  sectionTitle = '',
  items = [],
  linkText = '',
  isDownloading = false,
  onLinkClick,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          css={{
            fontSize: '16px !important',
            fontWeight: 700,
            color: colors.noirBlur,
            marginBottom: '16px',
          }}
        >
          {sectionTitle}
        </Typography>
        {linkText && (
          <Typography
            data-print-exclude
            onClick={onLinkClick}
            css={{
              color: colors.sparkBlue,
              fontWeight: 700,
              fontSize: '16px !important',
              cursor: 'pointer',
              pointerEvents: isDownloading ? 'none' : 'auto',
            }}
            role="button"
          >
            {linkText === 'Download all' ? (
              <>
                <span
                  css={{
                    verticalAlign: 'middle',
                  }}
                >
                  {isDownloading ? (
                    <CircularProgress size={18} css={{ marginRight: 4 }} />
                  ) : (
                    <SaveAltIcon />
                  )}
                </span>
                {linkText}
              </>
            ) : (
              linkText
            )}
          </Typography>
        )}
      </Box>

      {items.map((item, idx) => (
        <Box key={item.label} css={{ padding: '0px 18px 0 18px' }}>
          <Typography
            variant={'body1'}
            css={{
              fontSize: '16px !important',
              fontWeight: 700,
              color: colors.noirBlur,
            }}
          >
            {item.label}
          </Typography>
          {item.values.map(val => (
            <Typography
              variant="body2"
              css={{
                fontSize: '16px !important',
                color: colors.noirBlur,
                marginTop: '6px',
                marginBottom: '16px',
              }}
            >
              {val}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default JobSummarySection;
