import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { JobType } from '../quote.types';

export default function getJobTypeOptions(content: PageTextContentType) {
  return [
    {
      value: JobType.Job1,
      label: `${content.get('REUSABLE_JOB_TYPE_STANDARD_1')} ${content.get(
        'REUSABLE_JOB_TYPE_STANDARD_1_BASIC_LENGTH',
      )}`,
    },
    {
      value: JobType.Job2,
      label: `${content.get('REUSABLE_JOB_TYPE_STANDARD_2')} ${content.get(
        'REUSABLE_JOB_TYPE_STANDARD_2_EXTENDED_LENGTH',
      )}`,
    },
    {
      value: JobType.Job3,
      label: `${content.get('REUSABLE_JOB_TYPE_SEMI_CUSTOM_1')} ${content.get(
        'REUSABLE_JOB_TYPE_STANDARD_2_EXTERIOR_EV',
      )}`,
    },
    {
      value: JobType.Job4,
      label: `${content.get('REUSABLE_JOB_TYPE_SEMI_CUSTOM_2')} ${content.get(
        'REUSABLE_JOB_TYPE_SEMI_CUSTOM_PANEL_UPGRADE',
      )}`,
    },
    {
      value: JobType.Job5,
      label: `${content.get('REUSABLE_JOB_TYPE_SEMI_CUSTOM_3')} ${content.get(
        'REUSABLE_JOB_TYPE_SEMI_CUSTOM_PANEL_UPGRADE_SERVICE_UPGRADE',
      )}`,
    },
    {
      value: JobType.Job6,
      label: `${content.get('REUSABLE_JOB_TYPE_SEMI_CUSTOM_4')} ${content.get(
        'REUSABLE_JOB_TYPE_SEMI_CUSTOM_PANEL_UPGRADE_SERVICE_UPGRADE_METER_MAIN',
      )}`,
    },
    {
      value: JobType.Job7,
      label: `${content.get('REUSABLE_JOB_TYPE_CUSTOM')} ${content.get(
        'REUSABLE_JOB_TYPE_CUSTOM_ANYTHING',
      )}`,
    },
    {
      value: JobType.Job8,
      label: `${content.get('REUSABLE_JOB_TYPE_MISCELLANEOUS')} ${content.get(
        'REUSABLE_JOB_TYPE_MISCELLANEOUS_OTHER',
      )}`,
    },
  ];
}
