import gql from 'graphql-tag';
import {
  GetJobInput,
  GetServiceProviderSettingsInput,
  GetServiceProviderUserOutput,
  QueryGetServiceProviderSettingsArgs,
  ServiceProviderSettings,
  ServiceProviderUserRole,
} from '../../__generated__/pgeplus-types';
import useInstallerAuthQuery from './useInstallerAuthQuery';
import useUtil from './useUtil';
import { AccountCustomer } from '../../__generated__/pge-types';
import { useQuery } from '@apollo/react-hooks';

const GET_SERVICE_PROVIDER_QUERY = gql`
  query Query($input: GetServiceProviderSettingsInput!) {
    getServiceProviderSettings(input: $input) {
      maxUsers
    }
  }
`;

const GET_SERVICE_PROVIDER_INFO_QUERY = gql`
  query GetServiceProviderUserInfo {
    getServiceProviderUserInfo {
      role
    }
  }
`;

export const getAccountInfo = gql`
  query getAccountInfo($params: GetAccountInfoParams) {
    getAccountInfo(params: $params) {
      personName
      encryptedPersonId
    }
  }
`;

export const useServiceProviderInfo = () => {
  const { setErrorNotification } = useUtil();

  const { loading, data } = useInstallerAuthQuery<{
    getServiceProviderSettings: ServiceProviderSettings;
    input: GetServiceProviderSettingsInput;
  }>(GET_SERVICE_PROVIDER_QUERY, {
    variables: {
      input: {
        serviceProviderId: '',
      },
    },
    onError: () => setErrorNotification(true),
  });

  const {
    loading: serviceProviderInfoLoading,
    data: serviceProviderInfoData,
  } = useInstallerAuthQuery<{
    getServiceProviderUserInfo: GetServiceProviderUserOutput;
  }>(GET_SERVICE_PROVIDER_INFO_QUERY, {
    onError: () => setErrorNotification(true),
  });

  const { data: accountInfo } = useQuery<{
    getAccountInfo: AccountCustomer;
  }>(getAccountInfo, {
    context: {
      installerPortal: true,
    },
    errorPolicy: 'all',
  });

  return {
    loading: loading || serviceProviderInfoLoading,
    maxAllowedUsers: data?.getServiceProviderSettings?.maxUsers,
    role: serviceProviderInfoData?.getServiceProviderUserInfo?.role,
    personName: accountInfo?.getAccountInfo?.personName || '',
  };
};
