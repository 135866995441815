import React, { FunctionComponent as FC, useState, useEffect } from 'react';
import { Typography, Grid, Box, useTheme } from '@material-ui/core';
import { navigate, useParams } from '@reach/router';
import { Layout } from '../../pge-plus-common/Layout';
import colors from '../../../themes/main-colors';
import useContentMgmt from '../../../hooks/useContentMgmt';
import ROUTES from '../../../routes';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { JobDetailsCard } from '../../pge-plus-common/JobDetailsCard';
import InstallerPageHeader from '../../pge-plus-common/InstallerPageHeader';
import { useInstallerJobDetailsQuery } from '../../../hooks/pge-plus/useInstallerJobDetailsQuery';

import Backdrop from '../../backdrop/Backdrop';
import InstallerButton from '../../pge-plus-common/InstallerButton';

interface Props {
  path?: string;
}

const JobDetails: FC<Props> = props => {
  const { jobId } = useParams();
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_REVIEW_LEAD,
  );
  const theme = useTheme();

  const {
    loading,
    error,
    data,
    refetch: jobDetailsRefetch,
  } = useInstallerJobDetailsQuery(jobId);

  return (
    <Layout>
      {loading && <Backdrop forceOpen />}
      {data && (
        <>
          <Grid
            container
            css={{
              marginTop: 16,
              marginBottom: 96,
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} md={7}>
              <InstallerPageHeader
                pageHeaderInfo={{
                  pageTitle: content.get('JOB_DETAILS_HEADER'),
                }}
                jobData={data?.getJob}
                content={content}
              />
              <JobDetailsCard
                content={content}
                data={data.getJob}
                isLinkDownload={false}
                showCustomerInfoSection={false}
              />
              <Box mt={2}>
                <Typography
                  variant={'body1'}
                  css={{ fontSize: '14px !important' }}
                >
                  {content.get('LEGAL_TEXT')}
                </Typography>
              </Box>
              <Box
                mt={5}
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  [theme.breakpoints.down('sm')]: {
                    alignItems: 'center',
                    height: '96px',
                    backgroundColor: colors.cloudGrey,
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    left: 0,
                    boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
                  },
                }}
              >
                <InstallerButton
                  color={'primary'}
                  variant={'contained'}
                  onClick={() =>
                    void navigate(ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING)
                  }
                >
                  {content.get('REUSABLE_BACK_TO_HOME')}
                </InstallerButton>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default JobDetails;
