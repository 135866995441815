import React, { FunctionComponent as FC, useState, useEffect } from 'react';
import { Typography, Grid, Box, useTheme } from '@material-ui/core';
import { navigate, useParams } from '@reach/router';
import { Layout } from '../../pge-plus-common/Layout';
import colors from '../../../themes/main-colors';
import useContentMgmt from '../../../hooks/useContentMgmt';
import ROUTES from '../../../routes';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { useInstallerJobDetailsQuery } from '../../../hooks/pge-plus/useInstallerJobDetailsQuery';
import {
  ApplicationEvSectionType,
  SectionEvInstallData,
} from '../../../__generated__/pgeplus-types';
import { titleCase } from '../../../util/string-utils';
import Backdrop from '../../backdrop/Backdrop';
import { getTimeRangeString, parseTimeRange } from '../../../util/date-utils';
import InstallerButton from '../../pge-plus-common/InstallerButton';
import InstallerPageHeader from '../../pge-plus-common/InstallerPageHeader';

interface Props {
  path?: string;
}

const JobRescheduleOrCancel: FC<Props> = props => {
  const { jobId } = useParams();

  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_RESCHEDULE_CANCEL,
  );

  const { loading, data, error } = useInstallerJobDetailsQuery(jobId);

  const installationInterval = data?.getJob.job.quote?.serviceTime
    ? getTimeRangeString(data?.getJob.job.quote?.serviceTime)
    : { date: '', timeRange: '' };

  const evInstallData = data?.getJob.application.details.find(
    section => section.sectionType === ApplicationEvSectionType.EvInstall,
  )?.data as SectionEvInstallData;

  const getEvInstallData = (): SectionEvInstallData => {
    return {
      schedulePreference: evInstallData?.schedulePreference,
    };
  };

  const EV_INSTALLATION_DATA = getEvInstallData();
  const theme = useTheme();

  return (
    <Layout>
      {loading && <Backdrop forceOpen />}
      {data && (
        <>
          <Grid
            container
            css={{
              marginTop: 16,
              marginBottom: 96,
              justifyContent: 'center',
            }}
          >
            <Grid item container xs={12} md={7} css={{ rowGap: 30 }}>
              <Grid item xs={12}>
                <InstallerPageHeader
                  pageHeaderInfo={{
                    pageTitle: content.get('RESCHEDULE_OR_CANCEL_HEADER'),
                  }}
                  jobData={data?.getJob}
                  content={content}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={'body1'}
                  css={{
                    fontSize: '20px !important',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '16px !important',
                    },
                  }}
                >
                  {content.get('CUSTOMER_REQUEST')}
                </Typography>
                <Typography
                  variant={'body1'}
                  css={{
                    fontSize: '20px !important',
                    fontWeight: 700,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '16px !important',
                    },
                  }}
                >
                  {EV_INSTALLATION_DATA.schedulePreference?.dayOfWeek?.map(
                    (day, index, days) => {
                      if (index < days.length - 1) {
                        return `${titleCase(day)}, `;
                      } else {
                        return titleCase(day);
                      }
                    },
                  )}
                </Typography>
                <Typography
                  variant={'body1'}
                  css={{
                    fontSize: '20px !important',
                    fontWeight: 700,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '16px !important',
                    },
                  }}
                >
                  {EV_INSTALLATION_DATA.schedulePreference?.timeFrame?.map(
                    (time, index, timeFrames) => {
                      if (index < timeFrames.length - 1) {
                        return `${titleCase(time)}, `;
                      } else {
                        return titleCase(time);
                      }
                    },
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={'body1'}
                  css={{
                    fontSize: '20px !important',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '16px !important',
                    },
                  }}
                >
                  {content.get('REUSABLE_INSTALLATION_DATE_AND_TIME')}
                </Typography>
                <Typography
                  variant={'body1'}
                  css={{
                    fontSize: '20px !important',
                    fontWeight: 700,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '16px !important',
                    },
                  }}
                >
                  {installationInterval?.date}
                </Typography>
                <Typography
                  variant={'body1'}
                  css={{
                    fontSize: '20px !important',
                    fontWeight: 700,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '16px !important',
                    },
                  }}
                >
                  {installationInterval?.timeRange}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  mt={3}
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: 30,
                    [theme.breakpoints.down('sm')]: {
                      alignItems: 'center',
                      height: '96px',
                      backgroundColor: colors.cloudGrey,
                      position: 'fixed',
                      bottom: 0,
                      width: '100%',
                      left: 0,
                      boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
                    },
                  }}
                >
                  <InstallerButton
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() =>
                      void navigate(
                        ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_RESCHEDULE.replace(
                          ':jobId',
                          jobId,
                        ),
                      )
                    }
                  >
                    {content.get('RESCHEDULE_BUTTON')}
                  </InstallerButton>
                  <InstallerButton
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() =>
                      void navigate(
                        ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_CANCEL.replace(
                          ':jobId',
                          jobId,
                        ),
                      )
                    }
                  >
                    {content.get('CANCEL_JOB_BUTTON')}
                  </InstallerButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default JobRescheduleOrCancel;
