import { QueryHookOptions } from '@apollo/react-hooks';
import { OperationVariables } from 'apollo-client';
import { DocumentNode } from 'graphql';
import usePgeQuery from '../usePgeQuery';
import useInstallerAuth from './useInstallerAuth';
import useUtil from './useUtil';

export default function useAuthQuery<
  TData = any,
  TVariables = OperationVariables
>(query: DocumentNode, options: QueryHookOptions<TData, TVariables> = {}) {
  const { isAuthenticated } = useInstallerAuth();
  const { setErrorNotification } = useUtil();
  return usePgeQuery<TData, TVariables>(query, {
    ...options,
    onError: () => setErrorNotification(true),
    context: {
      installerPortal: true,
      pgePlus: true,
    },
    skip: !isAuthenticated || options.skip,
  });
}
