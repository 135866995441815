/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MenuItem } from '@material-ui/core';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import theme from '../../../../../themes/theme';
import { JobStatus } from '../../../../../__generated__/pgeplus-types';
import { PageTextContentType } from '../../../../../hooks/useContentMgmt';

type FilterProps = {
  content: PageTextContentType;
  filter: (status: JobStatus[]) => void;
  setOpenDrawer?: Dispatch<SetStateAction<boolean>>;
  setFilterByVal?: Dispatch<SetStateAction<string | undefined>>;
  value?: JobStatus[];
};

export const Filter = (props: FilterProps) => {
  const { content, filter, setOpenDrawer, setFilterByVal } = props;

  const handleClick = (status: JobStatus[], displayValue?: string) => {
    filter(status);
    setFilterByVal && setFilterByVal(displayValue);
    setOpenDrawer && setOpenDrawer(false);
  };

  const isHighlighted = (status: JobStatus[]) => {
    return status.join() === props.value?.join();
  };

  return (
    <Fragment>
      <MenuItem
        css={{
          fontSize: theme.spacing(2),
          ...(isHighlighted([]) ? { fontWeight: 'bold' } : {}),
        }}
        onClick={() => handleClick([], content.get('ALL'))}
      >
        {content.get('ALL')}
      </MenuItem>
      <MenuItem
        css={{
          fontSize: theme.spacing(2),
          ...(isHighlighted([JobStatus.Created]) ? { fontWeight: 'bold' } : {}),
        }}
        onClick={() => handleClick([JobStatus.Created], content.get('LEADS'))}
      >
        {content.get('LEADS')}
      </MenuItem>
      <MenuItem
        css={{
          fontSize: theme.spacing(2),
          ...(isHighlighted([JobStatus.Accepted, JobStatus.InProgress])
            ? { fontWeight: 'bold' }
            : {}),
        }}
        onClick={() =>
          handleClick(
            [JobStatus.Accepted, JobStatus.InProgress],
            content.get('OPEN_JOB'),
          )
        }
      >
        {content.get('OPEN_JOB')}
      </MenuItem>
      <MenuItem
        css={{
          fontSize: theme.spacing(2),
          ...(isHighlighted([JobStatus.Completed, JobStatus.ServiceCompleted])
            ? { fontWeight: 'bold' }
            : {}),
        }}
        onClick={() =>
          handleClick(
            [JobStatus.Completed, JobStatus.ServiceCompleted],
            content.get('COMPLETED_JOBS'),
          )
        }
      >
        {content.get('COMPLETED_JOBS')}
      </MenuItem>
      <MenuItem
        css={{
          fontSize: theme.spacing(2),
          ...(isHighlighted([JobStatus.Rejected])
            ? { fontWeight: 'bold' }
            : {}),
        }}
        onClick={() =>
          handleClick([JobStatus.Rejected], content.get('DECLINED'))
        }
      >
        {content.get('DECLINED')}
      </MenuItem>
      <MenuItem
        css={{
          fontSize: theme.spacing(2),
          ...(isHighlighted([JobStatus.Canceled])
            ? { fontWeight: 'bold' }
            : {}),
        }}
        onClick={() =>
          handleClick([JobStatus.Canceled], content.get('CANCELLED_JOBS'))
        }
      >
        {content.get('CANCELLED_JOBS')}
      </MenuItem>
      <MenuItem
        css={{
          fontSize: theme.spacing(2),
          ...(isHighlighted([JobStatus.Expired]) ? { fontWeight: 'bold' } : {}),
        }}
        onClick={() => handleClick([JobStatus.Expired], 'Expired')}
      >
        {content.get('EXPIRED')}
      </MenuItem>
    </Fragment>
  );
};
