/** @jsx jsx */
import { jsx } from '@emotion/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { InputLabelProps } from '@material-ui/core/InputLabel/InputLabel';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SelectProps } from '@material-ui/core/Select/Select';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import React, { ReactNode, forwardRef } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
      backgroundColor: '#fff',
    },
    selectEmpty: {
      //     marginTop: theme.spacing(2),
    },
    selectBox: {
      backgroundColor: '#fff',
    },
  }),
);

export interface SelectionValues {
  value?: any;
  label?: ReactNode;
  menuItemProps?: Partial<MenuItemProps>;
}

export interface DropdownProps extends Partial<SelectProps> {
  inputLabel?: ReactNode;
  inputLabelProps?: Partial<InputLabelProps>;
  selectionList: SelectionValues[];
  label?: string;
}

const Dropdown = forwardRef<HTMLElement, DropdownProps>(
  ({ selectionList, inputLabelProps, label, ...props }, ref?) => {
    const theme = useTheme();
    const classes = useStyles();
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
      setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    return (
      <React.Fragment>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth
        >
          <InputLabel ref={inputLabel} {...inputLabelProps}>
            {label}
          </InputLabel>
          <Select
            className={classes.selectBox}
            labelWidth={labelWidth}
            ref={ref}
            {...props}
          >
            {selectionList.map((item, index) => (
              <MenuItem
                key={`values-${index}`}
                value={item.value}
                css={{
                  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                    marginBottom: '129px',
                    whiteSpace: 'normal',
                    borderBottom: '1px solid #D7D7D7',
                    margin: '0px 15px 0px 15px',
                    width: 'calc(100% - 30px)',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  },
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  },
);

export default Dropdown;
