import gql from 'graphql-tag';

export const acceptJobMutation = gql`
  mutation AcceptJob($input: AcceptJobInput!) {
    acceptJob(input: $input) {
      accepted
      job {
        id
      }
    }
  }
`;

export const rejectJobMutation = gql`
  mutation RejectJob($input: RejectJobInput!) {
    rejectJob(input: $input) {
      rejected
      job {
        id
      }
    }
  }
`;

export const cancelJobMutation = gql`
  mutation CancelJob($input: CancelJobInput!) {
    cancelJob(input: $input) {
      canceled
      job {
        id
      }
    }
  }
`;

export const rescheduleJobMutaton = gql`
  mutation RescheduleJob($input: RescheduleJobInput!) {
    rescheduleJob(input: $input) {
      quote {
        id
      }
    }
  }
`;

export const updateJobMutation = gql`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      job {
        id
        applicationId
        applicationNo
        isOpened
        status
      }
    }
  }
`;
