import gql from 'not-graphql-tag';
import {
  CalculateEvRebateAmountsInput,
  CalculateEvRebateAmountsOutput,
  QueryCalculateEvRebateAmountsArgs,
} from '../../__generated__/pgeplus-types';
import { useApolloClient } from '@apollo/react-hooks';

export const calculateRebateQuery = gql`
  query CalculateEVRebateAmounts($input: CalculateEVRebateAmountsInput!) {
    calculateEVRebateAmounts(input: $input) {
      owedCustomerRebateAmount
      installerGivenRebates {
        chargerRebate
        installationRebate
      }
    }
  }
`;

const useCalculateRebate = () => {
  const apolloClient = useApolloClient();

  const calculateEVRebateAmounts = async (
    input: CalculateEvRebateAmountsInput,
  ) => {
    return apolloClient.query<
      { calculateEVRebateAmounts: CalculateEvRebateAmountsOutput },
      QueryCalculateEvRebateAmountsArgs
    >({
      query: calculateRebateQuery,
      variables: {
        input,
      },
      context: { pgePlus: true, installerPortal: true },
      fetchPolicy: 'network-only',
    });
  };

  return {
    calculateEVRebateAmounts,
  };
};

export default useCalculateRebate;
