import React, { FC, Fragment, ReactNode } from 'react';
import { useLocation, useParams } from '@reach/router';
import ROUTES from '../../../routes';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import InstallerSuccess from '../success-page';

interface Props {
  path?: string;
}
const SubmitQuote: FC<Props> = props => {
  const { content: quoteCompleteContent } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_COMPLETE_QUOTE,
  );
  const { content: quoteconfirmContent, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_CONFIRMATION_DETAILS_AND_COST,
  );
  const location = useLocation();
  const currentPath = location.pathname
    .replace(/\/$/, '')
    .split('/')
    .pop();
  let pageTitle = '';
  let pageSubText: ReactNode;
  const params = useParams();
  const jobId = params.jobId;

  switch (currentPath) {
    case ROUTES.PGE_PLUS_INSTALLER_PORTAL_SUCCESS.split('/').pop():
      pageTitle = quoteconfirmContent.get('COST_CONFIRMATION_SUCCESS_TITLE')!;
      pageSubText = richText(
        quoteconfirmContent.get('COST_CONFIRMATION_SUCCESS_TEXT'),
        {
          jobNumber: jobId,
        },
      );
      break;
    case ROUTES.PGE_PLUS_INSTALLER_PORTAL_QUOTE_SUBMITTED.split('/').pop():
      pageTitle = quoteCompleteContent.get('QUOTE_SUCCESS_HEADER')!;
      pageSubText = richText(
        quoteCompleteContent.get('QUOTE_SUCCESS_SUBTEXT'),
        {
          jobNumber: jobId,
        },
      );
      break;
    case ROUTES.PGE_PLUS_INSTALLER_PORTAL_REQUOTE_SUCCESS.split('/').pop():
      pageTitle = quoteCompleteContent.get('QUOTE_SUCCESS_HEADER')!;
      pageSubText = richText(
        quoteCompleteContent.get('QUOTE_SUCCESS_SUBTEXT'),
        {
          jobNumber: jobId,
        },
      );
      break;
  }
  return (
    <Fragment>
      <InstallerSuccess
        pageTitle={pageTitle}
        pageSubText={pageSubText}
        content={quoteconfirmContent}
      />
    </Fragment>
  );
};

export default SubmitQuote;
