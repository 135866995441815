import {
  Avatar,
  Divider,
  IconButton,
  MenuItem,
  MenuItemProps,
  Popover,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import theme from '../../../themes/theme';
import TextLink from '../../text-link';
import routes from '../../../routes';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import mainColors from '../../../themes/main-colors';
import useInstallerAuth from '../../../hooks/pge-plus/useInstallerAuth';
import { useServiceProviderInfo } from '../../../hooks/pge-plus/useInstallerAccountInfo';

type Props = {
  content: PageTextContentType;
};

const StyledMenuItem = ({
  children,
  handleClose,
}: {
  children: React.ReactNode;
  handleClose: () => void;
}) => {
  return (
    <MenuItem
      onClick={handleClose}
      css={{
        padding: theme.spacing(2, 2),
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      {children}
    </MenuItem>
  );
};

const ProfilePictureMenu = ({ content }: Props) => {
  const { authUser } = useInstallerAuth();
  const { role, personName } = useServiceProviderInfo();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const names = personName.split(', ');
  const CharacterAvatar = names[0].charAt(0);
  // Extracting first character of first name
  const firstNameInitial = names[0].charAt(0);
  // Extracting first character of last name
  const lastNameInitial = names[names.length - 1].charAt(0);

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        css={{ marginRight: theme.spacing(3) }}
      >
        <Avatar
          css={{ width: 42, height: 42, backgroundColor: mainColors.noirBlur }}
        >
          {firstNameInitial}
          {lastNameInitial}
        </Avatar>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        css={{
          '& .MuiPopover-paper': {
            minWidth: '210px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem handleClose={handleClose}>
          <Typography
            variant="h6"
            css={{ fontWeight: 'bold', color: mainColors.noirBlur }}
          >
            {personName} ({role || 'Editor'})
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {authUser?.email}
          </Typography>
        </StyledMenuItem>
        <Divider css={{ padding: '0 8px' }} />
        <StyledMenuItem handleClose={handleClose}>
          <TextLink plain to={routes.PGE_PLUS_INSTALLER_PORTAL_CHANGE_PASSWORD}>
            {content.get('CHANGE_PASSWORD_BUTTON')}
          </TextLink>
        </StyledMenuItem>
      </Popover>
    </>
  );
};

export default ProfilePictureMenu;
