import React, { Fragment } from 'react';
import { Router } from '@reach/router';
import JobList from '../../../components/installer-portal/job-list';
import JobDetails from '../../../components/installer-portal/job-details';
import JobLeadDetails from '../../../components/installer-portal/job-lead';
import CompleteQuote from '../../../components/installer-portal/complete-quote';
import PrivateInstallerRoute from '../../../components/installer-portal/routing/PrivateInstallerRoute';
import ROUTES from '../../../routes';
import ConfirmDetailsAndCost from '../../../components/installer-portal/confirm-cost';
import InstallerSuccess from '../../../components/installer-portal/success-page';
import SubmitQuote from '../../../components/installer-portal/submit-quote';
import JobRescheduleOrCancel from '../../../components/installer-portal/job-reschedule-or-cancel';
import { PendingCustomerResponse } from '../../../components/installer-portal/pending-customer-response';
import { ReQuote } from '../../../components/installer-portal/re-quote';
import JobAccepted from '../../../components/installer-portal/job-accepted';
import JobDeclined from '../../../components/installer-portal/job-declined';
import JobCancelled from '../../../components/installer-portal/job-cancelled';
import JobCompleted from '../../../components/installer-portal/job-completed';
import ChangePassword from '../../../components/installer-portal/profile/ChangePassword/ChangePassword';
import Profile from '../../../components/installer-portal/profile';
import JobCancel from '../../../components/installer-portal/job-cancel';
import JobReschedule from '../../../components/installer-portal/job-reschedule';
import ManageUsers from '../../../components/installer-portal/manage-users';
import ViewQuote from '../../../components/installer-portal/view-quote';

const SecureRouter = () => {
  return (
    <Fragment>
      <Router basepath="/">
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING}
          component={JobList}
        />

        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_DETAILS}
          component={JobDetails}
        />

        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LEAD}
          component={JobLeadDetails}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_ACCEPTED}
          component={JobAccepted}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_DECLINED}
          component={JobDeclined}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_CANCELLED}
          component={JobCancelled}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_COMPLETE_QUOTE}
          component={CompleteQuote}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_CONFIRMATION_DETAILS_AND_COST}
          component={ConfirmDetailsAndCost}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_SUCCESS}
          component={SubmitQuote}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_QUOTE_SUBMITTED}
          component={SubmitQuote}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_VIEW_QUOTE}
          component={ViewQuote}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_REQUOTE_SUCCESS}
          component={SubmitQuote}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_RESCHEDULE_OR_CANCEL}
          component={JobRescheduleOrCancel}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_CANCEL}
          component={JobCancel}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_RESCHEDULE}
          component={JobReschedule}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_CUSTOMER_RESPONSE}
          component={PendingCustomerResponse}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_REQUOTE}
          component={ReQuote}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_COMPLETED}
          component={JobCompleted}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_PROFILE + '*'}
          component={Profile}
        />
        <PrivateInstallerRoute
          path={ROUTES.PGE_PLUS_INSTALLER_PORTAL_MANAGE_USER}
          component={ManageUsers}
        />
      </Router>
    </Fragment>
  );
};
export default SecureRouter;
