import { Button, ButtonProps, useTheme } from '@material-ui/core';
import React, { forwardRef } from 'react';
import colors from '../../themes/main-colors';

export interface InstallerButtonProps
  extends Omit<ButtonProps, 'variant' | 'color'> {
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary';
}
const InstallerButton = forwardRef<HTMLButtonElement, InstallerButtonProps>(
  ({ children, variant, color, className, ...props }, ref) => {
    const theme = useTheme();
    return (
      <Button
        color={color}
        variant={variant}
        disableRipple={true}
        disableFocusRipple={true}
        {...props}
        css={{
          borderRadius: '30px',
          width: '180px',
          backgroundColor:
            variant === 'outlined'
              ? colors.white
              : color === 'primary'
              ? colors.sparkBlue
              : colors.orange,
          height: '48px',
          fontSize: '18px',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor:
              variant === 'outlined'
                ? colors.sparkBlue
                : color === 'primary'
                ? colors.sparkBlue
                : colors.orange,
            color: colors.white,
            boxShadow: 'none',
          },
          [theme.breakpoints.down('sm')]: {
            width: '45%',
            fontSize: '16px',
          },
          borderColor:
            variant === 'outlined' && color === 'primary'
              ? colors.sparkBlue
              : 'none',
          color: variant === 'outlined' ? colors.sparkBlue : colors.white,
          //   height: '48px',
          //   fontSize: '18px',
          //   '&:hover': {
          //     color: colors.white,
          //   },
        }}
      >
        {children}
      </Button>
    );
  },
);

export default InstallerButton;
