import React, { FC, Fragment, useEffect, useState } from 'react';
import Backdrop from '../../backdrop/Backdrop';
import { Grid, Paper, Typography } from '@material-ui/core';
import theme from '../../../themes/theme';
import { useIsMobile } from '../../../util/style-utils';
import { Installer2DesktopView } from './table/desktop';
import {
  GetJobsInput,
  ServiceProviderUserRole,
} from '../../../__generated__/pgeplus-types';
import { DeleteUserModel } from './DeleteUserModel';
import { Installer2MobileView } from './table/mobile';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { useServiceProviderInfo } from '../../../hooks/pge-plus/useInstallerAccountInfo';
import useUtil from '../../../hooks/pge-plus/useUtil';
import colors from '../../../themes/main-colors';
import { Link } from 'gatsby';
import ROUTES from '../../../routes';
import { useInstallerManageUser } from '../../../hooks/pge-plus/useInstallerManageUser';
import { AddUserModel } from './AddUserModel';
import { navigate } from '@reach/router';

export const DATE_FORMAT = 'MM/dd/yyyy';

interface Props {
  path?: string;
}

const DEFAULT_ITEMS_PER_PAGE = 20;

const ManageUsers: FC<Props> = () => {
  const isMobile = useIsMobile();
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_MANAGE_USER_SCREEN,
  );
  const [userId, setUserId] = useState(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isVisibleAdd, setIsVisibleAdd] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [input, setInput] = useState<GetJobsInput>({
    pagination: {
      page: 1,
      perPage: DEFAULT_ITEMS_PER_PAGE,
    },
    status: [],
  });

  const { getUsersList } = useInstallerManageUser();

  const { userData, refetchUsersList } = getUsersList();

  const start =
    ((input?.pagination?.page || 1) - 1) *
    (input?.pagination?.perPage || DEFAULT_ITEMS_PER_PAGE);
  const end = start + (input?.pagination?.perPage || 1);
  const [records, setRecords] = useState<any>([]);

  useEffect(() => {
    setRecords(userData?.slice(start, end));
  }, [userData]);

  const { loading, maxAllowedUsers, role } = useServiceProviderInfo();

  // useEffect(() => {
  //   if(userData?.length >= (maxAllowedUsers || 0)) {
  //     setErrorNotification(true, 'You have reached the maximum amount of users permitted. Message TBD')
  //   }
  // }, [maxAllowedUsers, userData]);

  const onPageChange = (newPage: number, newPageSize: number) => {
    setInput({ ...input, pagination: { page: newPage, perPage: newPageSize } });
    const newStart = (newPage - 1) * newPageSize;
    const newEnd = newStart + newPageSize;
    setRecords(userData?.slice(newStart, newEnd));
  };

  useEffect(() => {
    if (searchValue === '') {
      const newStart =
        ((input?.pagination?.page || 1) - 1) *
        (input?.pagination?.perPage || DEFAULT_ITEMS_PER_PAGE);
      const newEnd = newStart + (input?.pagination?.perPage || 2);
      setRecords(userData?.slice(newStart, newEnd));
    } else {
      const newData = userData?.filter(
        item =>
          item.firstName
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          item.lastName
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          item.email
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          item?.phoneNumber
            ?.toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()),
      );
      setRecords(newData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!loading && role === ServiceProviderUserRole.Editor) {
      void navigate(ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING);
    }
  }, [loading, role]);

  if (loading || role === ServiceProviderUserRole.Editor) {
    return <Backdrop forceOpen />;
  }

  return (
    <Fragment>
      {loading && <Backdrop forceOpen />}
      <DeleteUserModel
        isVisible={isVisible}
        userId={userId}
        selectedUser={selectedData}
        onClose={() => {
          setSelectedData({});
          setIsVisible(false);
        }}
        onSuccess={() => {
          void refetchUsersList();
          setIsVisible(false);
          setSelectedData({});
        }}
      />
      <AddUserModel
        isVisibleAdd={isVisibleAdd}
        selectedData={selectedData}
        onClose={() => {
          setSelectedData({});
          setIsVisibleAdd(false);
        }}
        onSuccess={() => {
          void refetchUsersList();
          setIsVisibleAdd(false);
          setSelectedData({});
        }}
      />
      <Grid
        container
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(7),
          marginBottom: theme.spacing(7),
        }}
      >
        <Grid
          xs={10}
          item
          css={{
            marginTop: 16,
            marginBottom: 24,
          }}
        >
          <span css={{ color: colors.sparkBlue }}>{'<'}</span>
          <Link
            to={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING}
            css={{
              fontSize: '18px',
              fontWeight: 700,
            }}
          >
            {content.get('BACK_TO_JOBS_LIST')}
          </Link>
        </Grid>
        <Grid item xs={10}>
          {!isMobile && (
            <Typography
              variant="h2"
              css={{
                fontWeight: 'normal',
                marginBottom: theme.spacing(2),
              }}
            >
              {`${content.get('MANAGE_USERS_TITLE') ||
                ''} (${userData?.length || 0})`}
            </Typography>
          )}
          <Paper
            css={{
              padding: theme.spacing(2),
              borderRadius: 0,
              boxShadow: 'none',
            }}
          >
            {isMobile ? (
              <Installer2MobileView
                onPageChange={onPageChange}
                input={input}
                maxAllowedUsers={maxAllowedUsers}
                data={userData}
                records={records}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                setIsVisibleAdd={setIsVisibleAdd}
                isVisibleAdd={isVisibleAdd}
                setUserId={setUserId}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
              />
            ) : (
              <Installer2DesktopView
                onPageChange={onPageChange}
                input={input}
                maxAllowedUsers={maxAllowedUsers}
                data={userData}
                records={records}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                setIsVisibleAdd={setIsVisibleAdd}
                isVisibleAdd={isVisibleAdd}
                setUserId={setUserId}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ManageUsers;
