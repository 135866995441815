import { convertValidationRules } from '../../../hooks/useFormState';

export type AddUserModal = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phone?: string;
  email?: string;
};

type AdditionalContext = {
  content: Map<string, string>;
};

export default convertValidationRules<AddUserModal, AdditionalContext>(
  context => {
    const { values, content } = context;

    return {
      firstName: () =>
        !values.firstName ? content.get('PLEASE_ENTER_FIRST_NAME') : null,
      lastName: () =>
        !values.lastName ? content.get('PLEASE_ENTER_LAST_NAME') : null,
      dateOfBirth: () =>
        !values.dateOfBirth ? content.get('PLEASE_SELECT_DATE_OF_BIRTH') : null,
      phone: () =>
        !values.phone ? content.get('PLEASE_ENTER_PHONE_NUMBER') : null,
      email: () => (!values.email ? content.get('PLEASE_ENTER_EMAIL') : null),
    };
  },
);
