import { convertValidationRules } from '../../../../hooks/useFormState';
import { BaseValidationContext } from '../../../../hooks/useFormState.types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { isValidPassword } from '../../../../util/form-validation';
const { t } = useTranslation();

export type Model = {
  password: string;
};

const validatePassword = async (
  password: string,
  context: BaseValidationContext<Model>,
): Promise<string | null> => {
  if (!password.length) {
    return t('ERROR_AUTH_PASSWORD_REQUIRED');
  }
  if (!isValidPassword(password)) {
    return t('ERROR_AUTH_INVALID_PASSWORD_RULES');
  }
  return null;
};

export default convertValidationRules<Model>(() => {
  return {
    // this must match the names of the form fields
    password: validatePassword,
  };
});
