import React, { FunctionComponent as FC } from 'react';
import { Typography, Grid, Box, useTheme } from '@material-ui/core';
import { Link, navigate, useLocation, useParams } from '@reach/router';
import { Layout } from '../../pge-plus-common/Layout';
import colors from '../../../themes/main-colors';
import useContentMgmt from '../../../hooks/useContentMgmt';
import ROUTES from '../../../routes';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { JobDetailsCard } from '../../pge-plus-common/JobDetailsCard';
import InstallerPageHeader from '../../pge-plus-common/InstallerPageHeader';
import { useInstallerJobDetailsQuery } from '../../../hooks/pge-plus/useInstallerJobDetailsQuery';
import Backdrop from '../../backdrop/Backdrop';
import InstallerButton from '../../pge-plus-common/InstallerButton';
import { JobStatus } from '../../../__generated__/pgeplus-types';

interface Props {
  path?: string;
}

const JobCancelled: FC<Props> = props => {
  const { jobId } = useParams();
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_CANCELLED_JOB,
  );
  const { content: jobDetailsContent } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_REVIEW_LEAD,
  );

  const { loading, error, data } = useInstallerJobDetailsQuery(jobId);
  const theme = useTheme();

  const getPageTitle = () => {
    if (data?.getJob?.job?.status === JobStatus.Canceled) {
      return jobDetailsContent.get('REVIEW_LEAD');
    } else {
      return jobDetailsContent.get('VIEW_LEAD');
    }
  };

  return (
    <Layout>
      {loading && <Backdrop forceOpen />}
      {data && (
        <>
          <Grid
            container
            css={{
              marginTop: 16,
              marginBottom: 96,
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} md={7}>
              <InstallerPageHeader
                pageHeaderInfo={{
                  pageTitle: getPageTitle(),
                }}
                cancelReasonInfo={
                  data?.getJob?.job?.status === JobStatus.Canceled
                    ? {
                        reasonTitle: content.get(
                          'REASON_FOR_CANCELLATION_TITLE',
                        ),
                        statusReason: data?.getJob?.job?.statusReason ?? '',
                        downloadPackageTitle: content.get(
                          'DOWNLOAD_LEAD_PACKAGE_BUTTON',
                        ),
                        hideDownloadOption: Boolean(data?.getJob?.job?.quote),
                      }
                    : undefined
                }
                content={jobDetailsContent}
                jobData={data?.getJob}
                parentPage={'job-cancelled'}
              />
              <JobDetailsCard
                content={jobDetailsContent}
                data={data.getJob}
                isLinkDownload={true}
                showCustomerInfoSection={false}
              />
              <Box
                mt={5}
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  [theme.breakpoints.down('sm')]: {
                    alignItems: 'center',
                    height: '96px',
                    backgroundColor: colors.cloudGrey,
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    left: 0,
                    boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
                  },
                }}
              >
                <InstallerButton
                  color={'primary'}
                  variant={'contained'}
                  onClick={() =>
                    void navigate(ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING)
                  }
                >
                  {content.get('BACK_TO_HOME')}
                </InstallerButton>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default JobCancelled;
