import React, { FunctionComponent as FC, useState, useEffect } from 'react';
import { Typography, Grid, Box, useTheme } from '@material-ui/core';
import { Link, navigate, useLocation, useParams } from '@reach/router';
import { Layout } from '../../pge-plus-common/Layout';
import colors from '../../../themes/main-colors';
import useContentMgmt from '../../../hooks/useContentMgmt';
import ROUTES from '../../../routes';
import DeclineJobConfirmationModal from '../../pge-plus-common/DeclineJobConfirmationModal';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { JobDetailsCard } from '../../pge-plus-common/JobDetailsCard';
import InstallerPageHeader from '../../pge-plus-common/InstallerPageHeader';
import { useInstallerJobDetailsQuery } from '../../../hooks/pge-plus/useInstallerJobDetailsQuery';
import {
  AcceptJobOutput,
  JobStatus,
  RejectJobOutput,
} from '../../../__generated__/pgeplus-types';
import Backdrop from '../../backdrop/Backdrop';
import { useMutation } from '@apollo/react-hooks';
import {
  acceptJobMutation,
  rejectJobMutation,
} from '../../../queries/pge-plus/installerPortal.query';
import useUtil from '../../../hooks/pge-plus/useUtil';
import InstallerButton from '../../pge-plus-common/InstallerButton';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';

interface Props {
  path?: string;
}

const JobLeadDetails: FC<Props> = props => {
  const { jobId } = useParams();
  const { setErrorNotification } = useUtil();
  const [jobAcceptedOrDeclined, setJobAcceptedOrDeclined] = useState<boolean>(
    false,
  );

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_REVIEW_LEAD,
  );

  const [jobDeclineModalOpen, setJobDeclineModalOpen] = useState(false);
  const { wrapWithLoader } = useWrapWithLoader();

  const {
    loading,
    error,
    data,
    refetch: jobDetailsRefetch,
  } = useInstallerJobDetailsQuery(jobId);
  const [rejectJob] = useMutation<{
    rejectJob: RejectJobOutput;
  }>(rejectJobMutation, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
  });

  const [acceptJob] = useMutation<{
    acceptJob: AcceptJobOutput;
  }>(acceptJobMutation, {
    context: {
      pgePlus: true,
      installerPortal: true,
    },
  });

  const theme = useTheme();

  const confirmJobDecline = wrapWithLoader(async () => {
    try {
      const { data: rejectJobData } = await rejectJob({
        variables: {
          input: {
            jobId: jobId,
          },
        },
      });
      if (rejectJobData?.rejectJob.rejected) {
        setJobDeclineModalOpen(false);
        setJobAcceptedOrDeclined(true);
        await jobDetailsRefetch();
        void navigate(
          ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_DECLINED.replace(
            ':jobId',
            jobId,
          ),
        );
      } else {
        setErrorNotification(true);
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const confirmJobAccept = wrapWithLoader(async () => {
    try {
      const { data: acceptJobData } = await acceptJob({
        variables: {
          input: {
            jobId: jobId,
          },
        },
      });
      if (acceptJobData?.acceptJob.accepted) {
        setJobAcceptedOrDeclined(true);
        await jobDetailsRefetch();
        void navigate(
          ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_ACCEPTED.replace(
            ':jobId',
            jobId,
          ),
        );
      } else {
        setErrorNotification(true);
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (
      data.getJob.job.status !== JobStatus.Created &&
      !jobAcceptedOrDeclined
    ) {
      void navigate(ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING);
    }
  }, [data]);

  return (
    <Layout>
      {loading && <Backdrop forceOpen />}
      {data && (
        <>
          <Grid
            container
            css={{
              marginTop: 16,
              marginBottom: 96,
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} md={7}>
              <InstallerPageHeader
                pageHeaderInfo={{
                  pageTitle: content.get('REVIEW_LEAD'),
                  pageSubTitle: content.get('REVIEW_LEAD_KICKER_TEXT'),
                }}
                jobData={data?.getJob}
                content={content}
              />
              <JobDetailsCard
                content={content}
                data={data.getJob}
                isLinkDownload={false}
                showCustomerInfoSection={false}
              />
              <Box mt={2}>
                <Typography
                  variant={'body1'}
                  css={{ fontSize: '14px !important' }}
                >
                  {content.get('LEGAL_TEXT')}
                </Typography>
              </Box>

              <Box
                mt={5}
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  columnGap: 30,
                  [theme.breakpoints.down('sm')]: {
                    alignItems: 'center',
                    height: '96px',
                    backgroundColor: colors.cloudGrey,
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    left: 0,
                    boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
                  },
                }}
              >
                <InstallerButton
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => setJobDeclineModalOpen(true)}
                >
                  {content.get('REUSABLE_BUTTON_DECLINE')}
                </InstallerButton>
                <InstallerButton
                  color={'secondary'}
                  variant={'contained'}
                  onClick={() => confirmJobAccept()}
                >
                  {content.get('REUSABLE_BUTTON_ACCEPT')}
                </InstallerButton>
              </Box>

              <DeclineJobConfirmationModal
                open={jobDeclineModalOpen}
                microcopyGroupId={
                  microcopyGroupIds.PGE_PLUS_INSTALLER_REVIEW_LEAD
                }
                data={{ applicationNo: data.getJob.application.applicationNo }}
                onClose={() => setJobDeclineModalOpen(false)}
                onJobDeclineConfirmation={() => confirmJobDecline()}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default JobLeadDetails;
