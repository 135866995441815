import gql from 'not-graphql-tag';
import {
  UpsertQuoteInput,
  UpsertQuoteOutput,
  MutationUpsertQuoteArgs,
} from '../../../__generated__/pgeplus-types';

export const createQuoteMutation = gql`
  mutation upsertQuote($input: UpsertQuoteInput!) {
    upsertQuote(input: $input) {
      quote {
        data {
          lineItems {
            ... on LineItemDetail {
              key
              name
              value
            }
            ... on LineItemCost {
              key
              name
              category
              amount
            }
            ... on LineItemProduct {
              key
              name
              value
              amount
            }
          }
        }
        jobId
        serviceTime {
          end
          start
        }
        total
      }
    }
  }
`;

export const submitJobDetailsMutation = gql`
  mutation submitJobDetails($input: SubmitJobDetailsInput!) {
    submitJobDetails(input: $input) {
      jobDetails {
        id
        jobId
        jobDate
        total
        comments
        createdAt
        updatedAt
        data {
          images {
            key
            name
            objectId
          }
        }
      }
    }
  }
`;
