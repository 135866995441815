export enum ChargerModal {
  HomeFlex_CPH50_NEMA = 'HomeFlex CPH50-NEMA 6-50-L23',
  HomeFlex_CPH25_L25_P = 'HomeFlex CPH25-L25-P',
  Home_X5 = 'Home X5',
  JuiceBox_48_2JBO481RNA = 'JuiceBox 48 2JBO481RNA',
  JuiceBox_32_2JBO321RNA = 'JuiceBox 32 2JBO321RNA',
  JuiceBox_40_2JBO321RNA = 'JuiceBox 40 2JBO321RNA',
}

export enum OuterPanelLocation {
  Left = 'Left',
  Back = 'Back',
  Right = 'Right',
}

export enum CardinalDirection {
  South = 'South',
  East = 'East',
  West = 'West',
  North = 'North',
  South_East = 'South East',
  South_West = 'South West',
  North_East = 'North East',
  North_West = 'North West',
}

export enum JobType {
  Job1 = 'Standard 1  - Basic length stand-alone installation of a new EV-charging circuit with 50-amp.',
  Job2 = 'Standard 2  - Extended length stand-alone installation of a new EV-charging circuit with 50-amp.',
  Job3 = 'Semi-Custom 1  - Exterior EV-charging circuit with 50-amp pulled from the breaker panel in the garage.',
  // Job4 = 'Semi-Custom  - Exterior EV-charging circuit with 50-amp pulled from the breaker panel in the basement.',
  Job4 = 'Semi-Custom 2  - Electric breaker panel replacement/upgrade to 200-amp panel.',
  Job5 = 'Semi-Custom 3 - Electric breaker panel replacement/upgraded to 200-amp panel. Electric service needs to be upgraded to a new 200-amp service.',
  Job6 = 'Semi-Custom 4 - Electric breaker panel replacement/upgraded to 200-amp panel. Electric service needs to be upgraded to a new 200-amp service. The meter main needs to be relocated and/or replaced.',
  Job7 = 'Custom  - Anything that is not described above.',
  Job8 = 'Miscellaneous  - Other non-EVSE related work.',
}

export interface calculateItems {
  totalInstallationMaterial: number;
  chargerCost: number;
  meterBase: number;
  weatherHead: number;
  electricBreaker: number;
  labor: number;
  miscMaterial: number;
  material: number;
  permitCost: number;
  travelCost: number;
  feesCost: number;
  miscellaneousWorkCost: number;
}

export enum LINE_ITEM_KEY {
  JOB_TYPE = 'JOB_TYPE',
  PANEL_LOCATION = 'PANEL_LOCATION',
  PANEL_LOCATION_MAIN_BREAKER = 'PANEL_LOCATION_MAIN_BREAKER',
  PREFERRED_INSTALL_DISTANCE = 'PREFERRED_INSTALL_DISTANCE',
  PANEL_LOCATION_CARDINAL_DIRECTION = 'PANEL_LOCATION_CARDINAL_DIRECTION',
  CHARGER_INSTALL_WITHIN_PANEL_DISTANCE = 'CHARGER_INSTALL_WITHIN_PANEL_DISTANCE',
  PANEL_UPGRADE = 'PANEL_UPGRADE',
  PANEL_AMPERAGE = 'PANEL_AMPERAGE',
  COMMENTS = 'COMMENTS',
  EV_CHARGER_MODEL = 'EV_CHARGER_MODEL',
  INSTALLATION_MATERIALS = 'INSTALLATION_MATERIALS',
  METER_BASE = 'METER_BASE',
  WEATHER_HEAD = 'WEATHER_HEAD',
  ELECTRICAL_BREAKER = 'ELECTRICAL_BREAKER',
  LABOR = 'LABOR',
  MISC_MATERIALS = 'MISC_MATERIALS',
  MATERIALS = 'MATERIALS',
  PERMITS = 'PERMITS',
  TRAVEL_TIME = 'TRAVEL_TIME',
  FEES = 'FEES',
  MISCELLANEOUS_WORK = 'MISCELLANEOUS_WORK',
  CUSTOMER_VISIBLE_NOTES = 'CUSTOMER_VISIBLE_NOTES',
}
