import React, { FC, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Button,
  Slide,
  useMediaQuery,
  useTheme,
  Paper,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import colors from '../../themes/main-colors';
import useContentMgmt, {
  PageTextContentType,
} from '../../hooks/useContentMgmt';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  microcopyGroupId: string;
  open: boolean;
  onClose: () => void;
  onCancelAppConfirmation: () => void;
  data: any;
};

const CancelApplicationConfirmationModal: FC<Props> = ({
  open,
  microcopyGroupId,
  onClose,
  onCancelAppConfirmation,
  data,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { content, richText } = useContentMgmt(microcopyGroupId);
  const { applicationNo } = data;

  return (
    <Dialog
      open={open}
      TransitionComponent={isMobile ? Transition : undefined}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      fullWidth={true}
      disableRestoreFocus={true}
      PaperProps={{
        style: {
          maxWidth: '530px',
          margin: 0,
        },
      }}
    >
      <Paper
        css={{
          [theme.breakpoints.down('sm')]: {
            maxWidth: '600px !important',
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
          },
        }}
      >
        <DialogTitle css={{ padding: '24px 24px 0' }}>
          <Grid
            container
            css={{
              justifyContent: 'end',
            }}
          >
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClose}
              css={{
                padding: 0,
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent
          css={{
            [theme.breakpoints.down('sm')]: {
              padding: '0 24px 40px',
            },
          }}
        >
          <Grid container>
            <Grid item>
              <Typography variant={'h1'} css={{ marginTop: '-30px' }}>
                {richText(content.get('ARE_YOU_SURE_YOU_WANT_TO_CANCEL'), {
                  jobNo: applicationNo,
                })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body1'}>
                {content.get('ACTION_CANNOT_BE_UNDONE')}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          css={{
            padding: '16px 24px 50px',
            [theme.breakpoints.down('sm')]: {
              backgroundColor: colors.cloudGrey,
              padding: '30px 0',
              boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
              opacity: 1,
            },
          }}
        >
          <Grid
            container
            css={{
              justifyContent: 'center',
              columnGap: '20px',
            }}
          >
            <Grid
              item
              xs={5}
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="button"
                fullWidth
                variant="outlined"
                onClick={onClose}
                css={{
                  borderRadius: '30px',
                  width: '93%',
                  borderColor: colors.sparkBlue,
                  color: colors.sparkBlue,
                  height: '48px',
                  fontSize: '18px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}
              >
                {content.get('NO_GO_BACK')}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                type="submit"
                color={'primary'}
                fullWidth
                variant="contained"
                css={{
                  borderRadius: '30px',
                  width: '93%',
                  backgroundColor: colors.sparkBlue,
                  height: '48px',
                  fontSize: '18px',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}
                onClick={onCancelAppConfirmation}
              >
                {content.get('YES_CANCEL')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default CancelApplicationConfirmationModal;
