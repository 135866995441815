import gql from 'graphql-tag';
import {
  Application,
  ApplicationEv,
  GetJobInput,
  GetJobOutput,
  ImageItemInput,
  Job,
  LineItem,
  LineItemCost,
  LineItemDetail,
  LineItemProduct,
  LineItemProgram,
  LineItemRebate,
  Quote,
  QuoteData,
  SectionEv,
  SectionEvAccountInfo,
  SectionEvChargerSelection,
  SectionEvPanelInfo,
  SectionEvRebates,
} from '../../__generated__/pgeplus-types';
import useInstallerAuthQuery from './useInstallerAuthQuery';
import { applicationEVFragment } from '../../components/pge-plus-common/queries/fragments';
import useAuthQuery from '../useAuthQuery';
import useUtil from './useUtil';

const GET_JOB_DETAILS_QUERY = gql`
  query GetJob($input: GetJobInput!) {
    getJob(input: $input) {
      application {
        id
        accountId
        applicationNo
        applicationType
        createdAt
        updatedAt
        statusReason
        status
        premiseId
        language
        ...applicationEVFragment
      }
      job {
        jobDetails {
          id
          jobId
          jobDate
          total
          comments
          createdAt
          updatedAt
          data {
            lineItems {
              key
              name
              ... on LineItemCost {
                category
                amount
              }
              ... on LineItemProduct {
                value
                amount
                productMeta {
                  serialNumber
                }
              }
              ... on LineItemProgram {
                value
              }
              ... on LineItemRebate {
                amount
              }
              ... on LineItemDetail {
                value
              }
            }
            images {
              key
              name
              objectId
              imageUrl
            }
          }
        }
        applicationId
        applicationNo
        createdAt
        id
        jobLocation {
          id
          jobId
          premiseId
          addressLine1
          city
          state
          postalCode
          firstName
          lastName
          middleName
          phone
          email
        }
        jobType
        quote {
          ... on QuoteEV {
            createdAt
            data {
              lineItems {
                key
                name
                ... on LineItemCost {
                  category
                  amount
                }
                ... on LineItemProduct {
                  value
                  amount
                }
                ... on LineItemProgram {
                  value
                }
                ... on LineItemRebate {
                  amount
                }
                ... on LineItemDetail {
                  value
                }
              }
              declineEnrollment
            }
            id
            jobId
            status
            serviceTime {
              start
              end
            }
            total
            updatedAt
            version
          }
        }
        serviceProviderId
        status
        statusReason
        updatedAt
      }
    }
  }
  ${applicationEVFragment}
`;

export type LineItemsTypes =
  | LineItem[]
  | LineItemCost[]
  | LineItemDetail[]
  | LineItemProduct[]
  | LineItemRebate[]
  | LineItemProgram[];
export type GetJobOutputEvType = {
  job: Job & {
    quote: Quote & {
      data: QuoteData & {
        lineItems: LineItemsTypes;
        images: ImageItemInput[];
      };
    };
  };
  application: Application & {
    details: [
      SectionEv &
        SectionEvAccountInfo &
        SectionEvRebates &
        SectionEvPanelInfo &
        SectionEvChargerSelection,
    ];
  };
};

export const useInstallerJobDetailsQuery = (jobId: string) => {
  const { setErrorNotification } = useUtil();
  const { loading, data, error, refetch } = useInstallerAuthQuery<{
    getJob: GetJobOutputEvType;
    input: GetJobInput;
  }>(GET_JOB_DETAILS_QUERY, {
    variables: {
      input: {
        jobId: jobId,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !jobId,
    onError: () => setErrorNotification(true),
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};
