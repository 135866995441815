import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import { PageSize } from './types';

const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      border: '0',
      marginLeft: theme.spacing(1),
      ...theme.typography.button,
    },
  }),
)(InputBase);

export type PaginationProps = {
  count: number;
  page: number;
  visibleOnSinglePage?: boolean;
  pageSize: PageSize;
  pageSizes: PageSize[];
  onPageChange: (page: number, pageSize: PageSize) => void;
  showPageString?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: theme.spacing(6),
    },
    label: {
      ...theme.typography.button,
      whiteSpace: 'nowrap',
    },
    icon: {
      color: colors.noirBlur,
      whiteSpace: 'nowrap',
    },
    select: {
      background: colors.siteBackground,
      '&:focus': {
        background: colors.siteBackground,
      },
    },
    paging: {
      width: '100%',
    },
  }),
);

const Pagination: FunctionComponent<PaginationProps> = ({
  count,
  pageSize,
  page,
  visibleOnSinglePage,
  onPageChange,
  pageSizes,
  showPageString,
}) => {
  //@ts-ignore
  const classes = useStyles();
  const { t } = useTranslation();
  const rowsPerPageLabel = t('ROWS_PER_PAGE');

  if (!count || !pageSize) {
    return null;
  }

  const lastPage = pageSize === 'All' ? 1 : Math.ceil(count / pageSize);

  const onPageSizeChange = (currentPageSize: PageSize) => {
    onPageChange(1, currentPageSize);
  };

  return (
    <React.Fragment>
      <Box
        className={classes.root}
        display={'flex'}
        justifyContent={'space-between'}
      >
        {pageSizes.length > 1 && (
          <Box display={'flex'}>
            <Box className={classes.label} display={'flex'} alignItems="center">
              <Typography variant={'body2'}>{rowsPerPageLabel}</Typography>
            </Box>
            <Select
              value={pageSize}
              onChange={e => {
                onPageSizeChange(e.target.value as PageSize);
              }}
              //@ts-ignore
              input={<StyledInput />}
            >
              {pageSizes.map(size => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {(visibleOnSinglePage || lastPage !== 1) && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            className={pageSizes.length > 1 ? '' : classes.paging}
          >
            <IconButton
              role="prev-page-button"
              aria-label="previous page"
              disabled={page === 1}
              onClick={() => onPageChange(page - 1, pageSize)}
            >
              <ChevronLeftIcon className={classes.icon} />
            </IconButton>
            <Box className={classes.icon}>{`${
              showPageString ? t('PAGE') + ' ' : ''
            }${page} of ${lastPage}`}</Box>
            <IconButton
              role="next-page-button"
              aria-label="next page"
              disabled={page === lastPage}
              onClick={() => onPageChange(page + 1, pageSize)}
            >
              <ChevronRightIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Pagination;
