/** @jsx jsx */
import { jsx } from '@emotion/core';
import { styled } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { Dispatch, SetStateAction } from 'react';
import theme from '../../../../../themes/theme';
import TextField from '../../../../text-field';
import { PageTextContentType } from '../../../../../hooks/useContentMgmt';

const StyledSearchField = styled(TextField)(() => ({
  ['& .MuiInputLabel-root']: {
    fontSize: theme.spacing(2),
  },
  ['& .MuiOutlinedInput-root']: {
    fontSize: theme.spacing(2),
  },
}));

type SearchProps = {
  content: PageTextContentType;
  search: (val: string) => void;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setOpenDrawer?: Dispatch<SetStateAction<boolean>>;
};

export const Search = (props: SearchProps) => {
  const { content, search, setOpenDrawer, searchValue, setSearchValue } = props;
  const handleSubmit = (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.preventDefault();
    search(searchValue);
    setOpenDrawer && setOpenDrawer(false);
  };
  return (
    <form onSubmit={handleSubmit} noValidate>
      <StyledSearchField
        name="search"
        label={content.get('SEARCH')}
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
        inputProps={{
          required: true,
        }}
        InputProps={{
          endAdornment: (
            <SearchIcon style={{ cursor: 'pointer' }} onClick={handleSubmit} />
          ),
        }}
        size="small"
      />
    </form>
  );
};
