import React, { ReactNode } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Link, navigate } from '@reach/router';
import { styled, useTheme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import PGEButton from '../../buttons';
import ROUTES from '../../../routes';
import { PageTextContentType } from '../../../hooks/useContentMgmt';

interface Props {
  pageTitle: string;
  pageSubText?: ReactNode;
  content: PageTextContentType;
}
const PREFIX = 'submit_quote';
const classes = {
  button: `${PREFIX}-button`,
  smartIcon: `${PREFIX}-smartIcon`,
};

const Root = styled(Box)(({ theme }) => ({
  [`& .${classes.button}`]: {
    background: colors.sparkBlue,
    width: '200px',
    borderRadius: '30px',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: colors.sparkBlue,
    },
  },
  [`& .${classes.smartIcon}`]: {
    height: '15px',
    width: '15px',
  },
}));

const InstallerSuccess = ({ pageSubText, pageTitle, content }: Props) => {
  const theme = useTheme();
  console.log('pageTitle', pageTitle);
  console.log('pageSubTitle', pageSubText);
  return (
    <Root>
      <Box
        display="flex"
        flexDirection="column"
        mt={1}
        css={{
          color: colors.noirBlurLight,
          maxWidth: '706px',
          margin: 'auto',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={12} css={{ margin: theme.spacing(4, 0, 0, 0) }}>
          <Grid
            xs={12}
            item
            css={{
              marginTop: 16,
            }}
          >
            <span css={{ color: colors.sparkBlue }}>{'<'}</span>
            <Link
              to={ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING}
              css={{
                fontSize: '18px',
                fontWeight: 700,
              }}
            >
              {content.get('BACK_TO_JOBS_LIST')}
            </Link>
          </Grid>
        </Grid>
        <Typography
          variant="h1"
          css={{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            color: '#3D5265',
            fontSize: '36px',
          }}
        >
          {pageTitle}
        </Typography>
        <Typography variant={'body2'} css={{ fontSize: 20 }}>
          {pageSubText}
        </Typography>
        <Grid item xs={12} css={{ margin: theme.spacing(4, 0, 6, 0) }}>
          <Box justifyContent={'end'} display={'flex'}>
            <PGEButton
              data-testid={'pay-online-button'}
              onClick={() =>
                navigate(ROUTES.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING)
              }
              size={'large'}
              className={classes.button}
            >
              {content.get('REUSABLE_BACK_TO_HOME')}
            </PGEButton>
          </Box>
        </Grid>
      </Box>
    </Root>
  );
};

export default InstallerSuccess;
