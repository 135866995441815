import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

import { styled } from '@material-ui/core/styles';

import React, { useEffect, useState } from 'react';
import { Close, ChevronLeft, ChevronRight } from '@material-ui/icons';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PageTextContentType } from '../../hooks/useContentMgmt';
const PREFIX = 'carousel';

const classes = {
  modal: `${PREFIX}-modal`,
  closeBtn: `${PREFIX}-closeBtn`,
  popupContent: `${PREFIX}-popupContent`,
  slider: `${PREFIX}-slider`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.modal}`]: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '960px',
    },
  },

  [`& .${classes.closeBtn}`]: {
    position: 'absolute',
    top: 24,
    right: 24,
    padding: 0,
  },

  [`& .${classes.popupContent}`]: {
    padding: '0px',
    '& .slick-next': {
      border: '2px solid red',
    },
  },

  [`& .${classes.slider}`]: {
    '& .slick-slider': {
      overflow: 'hidden',
    },
    '& .slick-prev': {
      zIndex: 1,
      left: '60px',
      bottom: '50px',
      position: 'absolute',
      top: 'initial',

      '& svg': {
        color: '#fff',
        width: '45px',
        height: '45px',
      },
    },
    '& .slick-prev:before': {
      content: 'none',
    },
    '& .slick-next': {
      zIndex: 1,
      right: '60px',
      bottom: '50px',
      top: 'initial',
      position: 'absolute',

      '& svg': {
        color: '#fff',
        width: '45px',
        height: '45px',
      },
    },
    '& .slick-next:before': {
      content: 'none',
    },
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  content: PageTextContentType;
  photos: {
    photo: string;
    title?: string;
    description?: string;
  }[];
}

export default ({ open, photos, onClose, content }: Props) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  useEffect(() => {
    setActiveSlide(0);
  }, [open]);

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: classes.slider,
    prevArrow: (
      <IconButton color="inherit">
        <ChevronLeft />
      </IconButton>
    ),
    nextArrow: (
      <IconButton color="inherit">
        <ChevronRight />
      </IconButton>
    ),
    afterChange: slide => setActiveSlide(slide),
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={classes.modal}
      scroll="body"
      disableScrollLock
    >
      <DialogTitle css={{ padding: '24px 24px 0' }}>
        <Grid
          className={'outageTitle'}
          container
          css={{
            justifyContent: 'end',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={onClose}
            className={classes.closeBtn}
            css={{
              padding: 0,
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        className={classes.popupContent}
        css={{ overflow: 'hidden', padding: '0 24px 40px' }}
      >
        <Typography
          variant={'h2'}
          noWrap
          css={{
            marginBottom: '20px',
          }}
        >
          {content.get('UPLOADED_DOCUMENTS_HEADER')}
        </Typography>
        <Root>
          <Slider {...settings}>
            {photos.map(item => (
              <Box css={{ width: '100%', height: '530px' }}>
                <img
                  src={item.photo}
                  css={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            ))}
          </Slider>
          <Grid container css={{ marginTop: '16px' }}>
            <Grid item>
              {photos[activeSlide].title && (
                <Typography variant="h4" gutterBottom>
                  {photos[activeSlide].title}
                </Typography>
              )}
              {photos[activeSlide].description && (
                <Typography variant="body2">
                  {photos[activeSlide].description}
                </Typography>
              )}
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Root>
      </DialogContent>
    </Dialog>
  );
};
